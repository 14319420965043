import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  text: string;
  className?: string;
};

function ProductBadge({ text, className }: Props) {
  return (
    <Container className={className}>
      <Inner>
        <Text>{text}</Text>
      </Inner>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: fit-content;
  padding-left: 14px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 14px 11px 0;
    border-color: transparent ${colors.primary} transparent transparent;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 13px 10px 0;
    border-color: transparent ${colors.white} transparent transparent;
    z-index: 1;
  }
`;

const Text = styled.span`
  color: ${colors.primary};
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
`;

const Inner = styled.div`
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
  background-color: ${colors.white};
  border-left: 0;
  padding: 0 5px 0 3px;
  white-space: nowrap;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
`;

export default ProductBadge;
