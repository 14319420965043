import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
};
function SearchModalResultTitle({ title }: Props) {
  return <Title>{title}</Title>;
}

export const Title = styled.div`
  padding: 70px 0;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
`;

export default SearchModalResultTitle;
