import parsePhoneNumber from 'libphonenumber-js/max';
import { FormikErrors, FormikTouched } from 'formik';

export function validatePhone(phone: string): boolean {
  return !!(
    parsePhoneNumber(phone, 'BY')?.isValid() ||
    parsePhoneNumber(phone, 'RU')?.isValid()
  );
}

export const areAllErrorsVisible = <T extends {}>(
  errors: FormikErrors<T>,
  touched: FormikTouched<T>
) =>
  (Object.keys(errors) as (keyof FormikErrors<T>)[]).every(
    (name) => !!touched[name]
  );
