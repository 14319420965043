import React from 'react';

import { Nullable } from '@tager/web-core';

import { Label, Value } from './ParamsBasis.style';

type Props = {
  param: {
    label: Nullable<string>;
    value: string;
  };
};

function ParamsBasis({ param }: Props) {
  return (
    <>
      {param.label ? <Label>{param.label}</Label> : null}
      <Value>{param.value}</Value>
    </>
  );
}

export default ParamsBasis;
