import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import useSettingItem from '@/hooks/useSettingItem';

export const announcementElementId = 'announcement-banner';

function AnnouncementBanner() {
  const bannerText = useSettingItem('HEADER_INTRO_TEXT');
  const bannerUrl = useSettingItem('HEADER_INTRO_URL');

  if (!bannerText) return null;

  return (
    <>
      {bannerUrl ? (
        <StyledLink to={bannerUrl}>
          <Container id={announcementElementId}>
            <Text>{bannerText}</Text>
          </Container>
        </StyledLink>
      ) : (
        <Container id={announcementElementId}>
          <Text>{bannerText}</Text>
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  min-width: 1260px;
  padding: 15px 30px;
  background-color: ${colors.gray};
  text-align: center;
  z-index: 60;
  box-shadow: rgba(0, 0, 0, 0.12) 0 3px 4px 0;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: ${colors.primary};
`;

const StyledLink = styled(Link)`
  &:hover ${Text} {
    color: ${colors.primary100};
  }
`;

export default AnnouncementBanner;
