import styled, { css } from 'styled-components';

import { PhoneNumberVariant } from '@/components/PhoneNumber/PhoneNumber.types';
import { getContentStyle } from '@/components/PhoneNumber/PhoneNumber.helpers';

export const BigMixin = css`
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  img,
  svg {
    height: 24px;
  }
`;

export const SmallBoldMixin = css`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  img,
  svg {
    height: 18px;
  }
`;

export const BigBoldMixin = css`
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
  img,
  svg {
    height: 24px;
  }
`;

export const PhoneLink = styled.a<{ variant: PhoneNumberVariant }>`
  transition: 0.3s;
  white-space: nowrap;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  ${(props) => getContentStyle(props.variant)};
`;

export const Comment = styled.span`
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
  color: #444;
`;
