import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { FETCH_STATUSES, generateNumberArray } from '@tager/web-core';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { CatalogMenuType } from '@/typings/model';
import {
  getMenuCategoryProductsByIdThunk,
  getMenuOfferProductsByIdThunk,
  selectMenuCategoryProductsByIdResource,
  selectMenuOfferProductsByIdResource,
} from '@/store/reducers/tager/menus';
import { useTypedSelector } from '@/store/store';
import ProductCardBig from '@/components/ProductCard/ProductCardBig';
import PlaceholderCard from '@/components/PlaceholderCard';
import Button from '@/components/Button';

type Props = {
  activeCatalogCategory: CatalogMenuType;
};

function SecondSubcategories({ activeCatalogCategory }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    getProductByIdThunk();
  }, [dispatch, activeCatalogCategory]);

  function getProductByIdThunk() {
    if (!activeCatalogCategory.entityId) return null;
    if (activeCatalogCategory.entityType === 'CATEGORY') {
      dispatch(
        getMenuCategoryProductsByIdThunk(activeCatalogCategory.entityId)
      );
    } else if (activeCatalogCategory.entityType === 'SPECIAL_OFFER') {
      dispatch(getMenuOfferProductsByIdThunk(activeCatalogCategory.entityId));
    }
  }

  const productResource = useTypedSelector((state) =>
    activeCatalogCategory.entityType === 'CATEGORY'
      ? selectMenuCategoryProductsByIdResource(
          state,
          activeCatalogCategory.entityId
        )
      : selectMenuOfferProductsByIdResource(
          state,
          activeCatalogCategory.entityId
        )
  );

  return (
    <Container isRow={activeCatalogCategory.view === 'CATEGORIES'}>
      {activeCatalogCategory.view === 'CATEGORIES' ? (
        activeCatalogCategory.children.inner.map((filter, index) => (
          <Filter key={filter.label + index}>
            {filter.url ? (
              <Link to={filter.url}>
                <Title isLink>{filter.label}</Title>
              </Link>
            ) : (
              <Title>{filter.label}</Title>
            )}

            <Category>
              {filter.children.map((filterCategory, index) => (
                <CategoryItem key={filterCategory.label + index}>
                  <StyledLink to={filterCategory.url}>
                    {filterCategory.label}
                  </StyledLink>
                </CategoryItem>
              ))}
            </Category>
          </Filter>
        ))
      ) : activeCatalogCategory.view === 'PRODUCTS' ||
        activeCatalogCategory.view === 'CATEGORIES_AND_PRODUCTS' ? (
        productResource?.status === FETCH_STATUSES.LOADING ? (
          <Products>
            {generateNumberArray(5).map((num) => (
              <ProductWrapper key={num}>
                <PlaceholderCard height={385} />
              </ProductWrapper>
            ))}
          </Products>
        ) : productResource?.data && !!productResource.data.length ? (
          <>
            <Products>
              {productResource.data.slice(0, 5).map((product) => (
                <ProductWrapper key={product.id}>
                  <Link to={`/products/${product.urlAlias}`}>
                    <ProductCardBig {...product} />
                  </Link>
                </ProductWrapper>
              ))}
            </Products>
            <Link to={activeCatalogCategory.url}>
              <StyledButton variant="outlined-primary-w100">
                Посмотреть все
              </StyledButton>
            </Link>
          </>
        ) : null
      ) : null}
    </Container>
  );
}

const Container = styled.div<{ isRow: boolean }>`
  display: flex;
  color: ${colors.black};
  ${({ isRow }) =>
    isRow
      ? css`
          flex-direction: row;
          padding: 25px 30px;
          ${media.laptop(
            css`
              padding: 15px 20px;
            `
          )}
        `
      : css`
          flex-direction: column;
          padding: 25px 30px;
          ${media.laptop(
            css`
              padding: 15px 20px;
            `
          )}
        `}
`;

const Filter = styled.div`
  margin: 0 110px 20px 0;
  &:last-child {
    margin-right: 0;
  }
  ${media.laptop(css`
    margin: 0 50px 20px 0;
  `)}
`;

const Title = styled.span<{ isLink?: boolean }>`
  display: block;
  line-height: 20px;
  font-weight: 600;
  color: #6e6e6e;
  transition: all 0.3s;
  &:hover {
    color: ${({ isLink }) => (isLink ? colors.primary100 : '')};
  }
`;

const Category = styled.ul`
  margin-top: 10px;
`;

const CategoryItem = styled.li`
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  &:hover {
    color: ${colors.primary};
  }
`;

const Products = styled.div`
  display: flex;
  margin: 0 -5px;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  ${media.laptop(
    css`
      margin-top: 15px;
    `
  )}
`;

const ProductWrapper = styled.div`
  flex-basis: calc(20% - 10px);
  margin: 0 5px;

  @media (max-width: 1740px) {
    flex-basis: calc(25% - 10px);
    &:nth-child(5) {
      display: none;
    }
  }

  ${media.laptop(
    css`
      flex-basis: calc(33.333% - 10px);
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    `
  )}
`;

export default SecondSubcategories;
