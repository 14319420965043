import React from 'react';

import { getCurrentProject } from '@/utils/common';

import {
  FooterBelmebel,
  FooterBelsex,
  FooterBelkids,
  FooterSamovarkin,
} from './FooterProject';

function Footer() {
  const project = getCurrentProject();

  switch (project) {
    case 'BELMEBEL':
      return <FooterBelmebel />;
    case 'BELSEX':
      return <FooterBelsex />;
    case 'BELKIDS':
      return <FooterBelkids />;
    case 'SAMOVARKIN':
      return <FooterSamovarkin />;
    default:
      return <FooterBelmebel />;
  }
}

export default Footer;
