import React from 'react';
import styled from 'styled-components';

import { MenuItemType } from '@tager/web-modules';
import { useModal } from '@tager/web-components';

import Link from '@/components/Link';
import Button from '@/components/Button';
import { colors } from '@/constants/theme';
import TextWithIcon from '@/components/TextWithIcon';
import useSettingItem from '@/hooks/useSettingItem';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { useTypedSelector } from '@/store/store';
import { FooterFeedbackForm } from '@/components/form/FeedbackForm';

import Contact from './Contact';

function Top() {
  const locationLabel = useSettingItem('FOOTER_LOCATION_BUTTON_LABEL');
  const locationLink = useSettingItem('FOOTER_LOCATION_BUTTON_URL');
  const addressTitle = useSettingItem('FOOTER_ADDRESS_TITLE');
  const addressText = useSettingItem('FOOTER_ADDRESS_TEXT');

  const openModal = useModal();

  const footerMenuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  return (
    <Container>
      {footerMenuItemList.map((navigationItem: MenuItemType) => (
        <Column key={navigationItem.id}>
          {navigationItem.link ? (
            <ColumnLink
              to={navigationItem.link}
              target={navigationItem.isNewTab ? '_blank' : '_self'}
            >
              {navigationItem.label}
            </ColumnLink>
          ) : (
            <ColumnTitle>{navigationItem.label}</ColumnTitle>
          )}
          <ul>
            {navigationItem.children.map((menuItem) => (
              <ColumnItem key={menuItem.id}>
                <Link
                  to={menuItem.link}
                  target={menuItem.isNewTab ? '_blank' : '_self'}
                >
                  {menuItem.label}
                </Link>
              </ColumnItem>
            ))}
          </ul>
        </Column>
      ))}

      <Column>
        <ColumnTitle>Контакты</ColumnTitle>
        <Contact />
      </Column>

      <Column>
        <ColumnTitle>{addressTitle}</ColumnTitle>
        <Shops>{addressText}</Shops>
        {locationLabel ? (
          <Location>
            <TextWithIcon
              icon="location"
              text={locationLabel}
              color="primary"
              to={locationLink}
              bold
            />
          </Location>
        ) : null}
        <Button
          variant="outlined-primary"
          onClick={() => openModal(FooterFeedbackForm, {})}
        >
          Обратная связь
        </Button>
      </Column>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0 25px;
  line-height: 22px;
`;

const Column = styled.div`
  &:last-child {
    padding-right: 55px;
  }
`;

const ColumnTitle = styled.span`
  display: block;
  font-weight: 800;
  margin-bottom: 15px;
`;

const ColumnLink = styled(Link)`
  display: block;
  font-weight: 800;
  margin-bottom: 15px;
`;

const ColumnItem = styled.li`
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 400;
  &:hover {
    color: ${colors.gray500};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const Shops = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  white-space: pre-line;
`;

const Location = styled.div`
  margin-bottom: 25px;
`;

export default Top;
