import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';

import Bottom from '../components/Bottom';

import TopBelsex from './components/TopBelsex';

function FooterBelsex() {
  return (
    <FooterContainer>
      <ContentContainer>
        <TopBelsex />
        <Bottom />
      </ContentContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background: ${colors.violet100};
  color: ${colors.white};
`;

export default FooterBelsex;
