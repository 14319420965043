import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  onAgree: () => void;
};

function AdultsMode({ onAgree }: Props) {
  return (
    <Container>
      <ContentContainer>
        <Inner>
          <Title>Вам уже есть 18 лет?</Title>
          <Text>
            Содержание сайта предназначено для просмотра исключительно
            совершенннолетних лиц
          </Text>
          <ButtonWrapper>
            <StyledButton isAgreeButton onClick={onAgree}>
              Да
            </StyledButton>
            <StyledButton onClick={() => window.history.back()}>
              Нет
            </StyledButton>
          </ButtonWrapper>
        </Inner>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2003;
  background-color: rgba(66, 34, 61, 0.95);
  display: flex;
  align-items: center;
`;

const Inner = styled.div`
  margin: auto;
  max-width: 750px;
  color: ${colors.white};
`;

const Title = styled.span`
  display: block;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;

  ${media.tablet(
    css`
      font-size: 40px;
      line-height: 44px;
    `
  )}
`;

const Text = styled.p`
  display: block;
  font-size: 28px;
  line-height: 32px;
  margin-top: 15px;
  color: #f8d6e4;

  ${media.tablet(
    css`
      font-size: 18px;
      line-height: 24px;
    `
  )}
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 35px;

  ${media.mobileMedium(
    css`
      flex-direction: column;
    `
  )}
`;

export const StyledButton = styled.button<{ isAgreeButton?: boolean }>`
  width: 120px;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  height: 38px;
  color: ${colors.white};
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
  &:last-child {
    margin-left: 25px;
  }

  ${media.mobileMedium(
    css`
      width: 100%;

      &:last-child {
        margin-left: 0;
        margin-top: 15px;
      }
    `
  )}

  ${({ isAgreeButton }) =>
    isAgreeButton
      ? css`
          background: #c1135a;
          border: 1.5px solid #c1135a;
          &:hover {
            opacity: 0.85;
          }
        `
      : css`
          border: 1.5px solid rgb(248, 214, 228, 0.7);
          &:hover {
            border-color: ${colors.white};
          }
        `};
`;

export default AdultsMode;
