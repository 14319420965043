import React from 'react';
import styled from 'styled-components';
import { Formik, Form, FormikHelpers } from 'formik';

import { sendCallForm } from '@/services/requests';
import Button from '@/components/Button';
import { TextInputFormik } from '@/components/Input/TextInput';
import { CallFormPayload } from '@/services/requests';
import { validatePhone } from '@/components/form/form.helpers';
import { areAllErrorsVisible } from '@/components/form/form.helpers';

type Props = {
  setCallFormClosed: () => void;
};

function validate({ phone, name }: CallFormPayload) {
  const errors: Partial<CallFormPayload> = {};
  if (!validatePhone(phone)) {
    errors.phone = 'Неверный номер телефона';
  }
  if (!phone) {
    errors.phone = 'Поле обязательно для заполнения';
  }
  if (!name) {
    errors.name = 'Поле обязательно для заполнения';
  }
  return errors;
}

const hasFrontendValidationErrors = (values: CallFormPayload) =>
  !!Object.keys(validate(values)).length;

function CallForm({ setCallFormClosed }: Props) {
  function handleSubmit(
    values: CallFormPayload,
    formikHelpers: FormikHelpers<CallFormPayload>
  ) {
    return sendCallForm(values)
      .then(() => {
        setCallFormClosed();
        formikHelpers.setSubmitting(true);
      })
      .catch(() => {
        formikHelpers.setErrors({
          phone: 'Произошла ошибка при отправке данных',
        });
        formikHelpers.setStatus({
          hasBackendError: true,
        });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }
  return (
    <Container>
      <Text>Оставьте ваши контакты и мы перезвоним</Text>
      <Formik<CallFormPayload>
        initialValues={{ name: '', phone: '' }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ isSubmitting, isValid, values, dirty, ...props }) => (
          <Form>
            <FormRow>
              <TextInputFormik
                name="name"
                icon="profile"
                placeholder="Как вас зовут?"
                autoComplete="off"
                invalid={false}
              />
            </FormRow>
            <FormRow>
              <TextInputFormik
                name="phone"
                icon="phone"
                placeholder="Ваш номер телефона"
                autoComplete="off"
                invalid={false}
              />
            </FormRow>
            <Button
              type="submit"
              variant="contained-primary-w100"
              disabled={
                !(isValid || !hasFrontendValidationErrors(values)) &&
                areAllErrorsVisible(props.errors, props.touched)
              }
              children="Отправить"
              loading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
}
const Container = styled.div`
  padding: 40px 50px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
`;

const FormRow = styled.div`
  margin-bottom: 30px;
`;

export default CallForm;
