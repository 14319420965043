import React from 'react';

import { PostShort } from '@/typings/model';
import { PostCardVariant } from '@/typings/common';
import PlaceholderCard from '@/components/PlaceholderCard/PlaceholderCard';

import PostCardSmall from './components/PostCardSmall';
import PostCardBig from './components/PostCardBig';
import PostCardSearch from './components/PostCardSearch';

type Props = {
  post: PostShort;
  loading?: boolean;
  variant: PostCardVariant;
  categoryHidden?: boolean;
};

export function getContentHeight(variant: PostCardVariant): number {
  switch (variant) {
    case 'big':
      return 300;
    case 'small':
      return 343;
    case 'search':
      return 170;
    default:
      return 0;
  }
}

function PostCard({ post, variant, loading = false, categoryHidden }: Props) {
  if (loading) {
    return <PlaceholderCard height={getContentHeight(variant)} />;
  }
  switch (variant) {
    case 'big':
      return (
        <PostCardBig
          {...post}
          coverImage={post.image?.coverBig}
          categoryHidden={categoryHidden}
        />
      );
    case 'small':
      return (
        <PostCardSmall
          {...post}
          coverImage={post.image?.cover}
          categoryHidden={categoryHidden}
        />
      );
    case 'search':
      return <PostCardSearch {...post} coverImage={post.image?.coverSearch} />;
    default:
      return null;
  }
}

export default PostCard;
