import React from 'react';
import styled from 'styled-components';

import Background from '@/assets/images/bg-samovarkin.png';
import ContentContainer from '@/components/ContentContainer';
import { PlainPicture } from '@/components/Picture';
import { colors } from '@/constants/theme';
import useSettingItem from '@/hooks/useSettingItem';

import TopSamovarkin from './components/TopSamovarkin';

function FooterSamovarkin() {
  const copyright = useSettingItem('FOOTER_COPYRIGHT');
  return (
    <FooterContainer>
      <StyledPlainPicture src={Background} />
      <TopSamovarkin />
      <Bottom>
        <ContentContainer>
          <Copyright>{copyright}</Copyright>
        </ContentContainer>
      </Bottom>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  position: relative;
  color: ${colors.white};
  max-height: 400px;
  z-index: 0;
  overflow: hidden;
`;

const StyledPlainPicture = styled(PlainPicture)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

const Bottom = styled.div`
  background: ${colors.primary100};
  height: 40px;
  display: flex;
  align-items: center;
`;

const Copyright = styled.div`
  font-size: 12px;
  line-height: 15px;
`;

export default FooterSamovarkin;
