import styled from 'styled-components';

import { colors } from '@/constants/theme';

export const Label = styled.span`
  color: ${colors.gray400};
  font-size: 13px;
  line-height: 100%;
`;

export const Value = styled.span`
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MainParam = styled.div`
  display: flex;
  flex: 1;
  margin: 10px 0;

  ${Label} {
    margin-right: 5px;
  }

  ${Value} {
    flex: 1;
    font-size: 12px;
  }
`;

export const ParamShort = styled.span`
  display: flex;
  flex-direction: column;

  width: 80px;

  ${Label} {
    margin-bottom: 5px;
  }
`;

export const ParamFull = styled.span`
  display: flex;
  font-size: 13px;
  line-height: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${Label} {
    width: 75px;
  }

  ${Value} {
    white-space: nowrap;
  }
`;
