import React, { ReactEventHandler, useEffect, useRef, useState } from 'react';

import * as S from './TextArea.style';

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  className?: string;
  invalid?: boolean;
  required?: boolean;
  autoHeight?: boolean;
  onBlur?: ReactEventHandler;
};

export type TextAreaRef = HTMLTextAreaElement;

function TextArea({
  label,
  placeholder,
  className,
  autoFocus,
  onBlur,
  disabled,
  required,
  invalid,
  autoHeight,
  onChange,
  ...rest
}: Props) {
  const [isActive, setActive] = useState<boolean>(false);
  const [isFilled, setFilled] = useState<boolean>(false);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    if (autoHeight) {
      const textAreaElement = event.target;
      textAreaElement.style.height = 'auto';
      textAreaElement.style.height = `${textAreaElement.scrollHeight}px`;
    }

    if (onChange) {
      onChange(event);
    }
  }

  function handleFocus() {
    if (!textAreaRef.current) return;

    setActive(true);
    toggleFilledState();
  }

  function handleBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
    if (!textAreaRef.current) return;

    setActive(false);
    toggleFilledState();

    onBlur && onBlur(e);
  }

  function toggleFilledState() {
    const textAreaElem = textAreaRef.current;
    if (!textAreaElem) return;

    if (textAreaElem.value !== '') setFilled(true);
    else setFilled(false);
  }

  useEffect(() => {
    toggleFilledState();

    const textAreaElem = textAreaRef.current;
    if (textAreaElem && autoFocus) textAreaElem.focus();
  }, [autoFocus]);

  return (
    <S.Container className={className}>
      <S.TextArea
        className={className}
        ref={textAreaRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        isActive={isActive}
        withLabel={!!label}
        disabled={disabled}
        invalid={invalid}
        placeholder={placeholder}
        {...rest}
      />
      {label ? (
        <S.Label {...{ isActive, isFilled, isInvalid: invalid }}>
          {label}
        </S.Label>
      ) : null}
    </S.Container>
  );
}

export default TextArea;
