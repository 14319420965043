import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useRouter} from 'next/router';

import {useModal} from '@tager/web-components';
import Overlay from '@tager/web-components/src/components/Overlay';
import {convertThumbnailToPictureProps} from '@tager/web-modules';

import {queryAdded, selectQuery} from '@/store/reducers/pages/search';
import {media} from '@/utils/mixin';
import {normalizePhoneNumber, normalizeThumbnail} from '@/utils/common';
import {colors} from '@/constants/theme';
import {ReactComponent as ViberIcon} from '@/assets/svg/social/viber.svg';
import {ReactComponent as SearchIcon} from '@/assets/svg/search.svg';
import {ReactComponent as HistoryIcon} from '@/assets/svg/history.svg';
import useSettingItem from '@/hooks/useSettingItem';
import SearchInput from '@/components/Input/SearchInput';
import Link from '@/components/Link';
import ContentContainer from '@/components/ContentContainer';
import Button from '@/components/Button';
import PhoneNumber from '@/components/PhoneNumber';
import {HeaderCallForm} from '@/components/form/CallForm';
import {PlainPicture} from '@/components/Picture';

import SearchModal from './SearchModal';

function Middle() {
  const router = useRouter();
  const phoneNumberA1 = useSettingItem('CONTACTS_PHONE_A1');
  const phoneNumberMts = useSettingItem('CONTACTS_PHONE_MTS');
  const phoneNumberViber = useSettingItem('CONTACTS_VIBER');
  const textViber = useSettingItem('HEADER_VIBER_TEXT');
  const logo = useSettingItem('HEADER_LOGO');
  const logo2x = useSettingItem('HEADER_LOGO_2X');
  const normalizePhoneNumberViber = normalizePhoneNumber(phoneNumberViber);
  const [isCallFormOpened, setCallFormOpened] = useState<boolean>(false);
  const searchQuery = useSelector(selectQuery);
  const openModal = useModal();
  const dispatch = useDispatch();

  const OpenSearchModal = () =>
    openModal(
      SearchModal,
      {},
      {components: {Overlay: ToppedOverlay}, withAnimation: false}
    );

  const openModalOrPage = !!searchQuery.trim().length
    ? () => router.push(`/search?query=${searchQuery}`)
    : OpenSearchModal;

  const thumbnail = normalizeThumbnail(logo, logo2x);
  return (
    <ContentContainer>
      <ContentWrapper>
        {logo || logo2x ? (
          <Logo>
            <LogoLink to="/">
              <PlainPicture {...convertThumbnailToPictureProps(thumbnail)} />
            </LogoLink>
          </Logo>
        ) : null}

        <PhonesWrapper>
          {phoneNumberA1 ? (
            <Wrapper>
              <PhoneNumber
                operator="a1"
                phoneNumber={phoneNumberA1}
                variant="small-bold"
              />
            </Wrapper>
          ) : null}
          {phoneNumberMts ? (
            <Wrapper>
              <PhoneNumber
                operator="mts"
                phoneNumber={phoneNumberMts}
                variant="small-bold"
              />
            </Wrapper>
          ) : null}
        </PhonesWrapper>

        <ButtonWrapper>
          <Button
            variant="call-me-dashed"
            onClick={() => setCallFormOpened(!isCallFormOpened)}
          >
            Перезвоните мне
          </Button>
          {isCallFormOpened ? (
            <HeaderCallForm onClose={() => setCallFormOpened(false)}/>
          ) : null}
        </ButtonWrapper>

        <ViberLink href={`viber://chat?number=${normalizePhoneNumberViber}`}>
          <ViberIcon/>
          {textViber}
        </ViberLink>

        <StyledSearchInput
          variant="default"
          placeholder="Поиск"
          value={searchQuery}
          onChange={(e) => {
            dispatch(queryAdded(e.target.value));
            OpenSearchModal();
          }}
          onKeyDown={(event) =>
            event.keyCode === 13 && !!searchQuery.trim().length
              ? router.push(`/search?query=${searchQuery}`)
              : OpenSearchModal
          }
          onClickButton={openModalOrPage}
        />
        <IconButton onClick={OpenSearchModal}>
          <SearchIcon/>
        </IconButton>
      </ContentWrapper>
    </ContentContainer>
  );
}

export const ToppedOverlay = styled(Overlay)`
  align-items: flex-start;
  padding-top: 30px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  min-height: 104px;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  img{
    max-height: 100px;
  }
`;

const LogoLink = styled(Link)`
  img {
    max-width: 200px;
    max-height: 80px;
  }
`;

const PhonesWrapper = styled.div`
  display: flex;
  ${media.laptopOnly(
  css`
      flex-direction: column;
    `
)}
`;

const Wrapper = styled.div`
  margin-right: 35px;
  &:last-child {
    margin-right: 0;
  }
  ${media.laptopOnly(
  css`
      margin: 0 0 7px 0;
      &:last-child {
        margin-bottom: 0;
      }
    `
)}
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const ViberLink = styled.a`
  white-space: nowrap;
  border: 0 none;
  text-decoration: underline;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: ${colors.black};
  &:hover {
    color: ${colors.violet};
  }

  svg {
    margin-right: 5px;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  width: 400px;
  ${media.laptopOnly(
  css`
      width: 360px;
    `
)}
  ${media.tablet(
  css`
      display: none;
      width: 0;
    `
)}
`;

const IconButton = styled.button`
  display: none;
  ${media.tablet(
  css`
      display: block;
      svg {
        fill: ${colors.primary};
        &:hover {
          fill: ${colors.primary100};
        }
      }
    `
)}
`;

export const CartLink = styled(Link)`
  position: relative;
  background: transparent;
  padding: 0;
  border: 0 none;
  flex-basis: 32px;
  svg {
    transition: all 0.3s ease-in-out;
    fill: ${colors.primary};

    &:hover {
      fill: ${colors.primary100};
    }
  }
`;

export default Middle;
