import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import Link from '@/components/Link';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import useSettingItem from '@/hooks/useSettingItem';
import { getParamAsString } from '@/utils/common';

function TopSamovarkin() {
  const router = useRouter();
  const headerTopMenuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'top')) ?? [];

  const phone = useSettingItem('CONTACTS_PHONE');
  const workTime = useSettingItem('FOOTER_WORK_TIME');

  const selectedMenuItem = getParamAsString(router.asPath);

  return (
    <Container>
      <ContentContainer>
        <Content>
          <MenuInfo>
            {phone ? <PhoneNumberText>{phone}</PhoneNumberText> : null}
            {workTime ? <WorkTimeText>{workTime}</WorkTimeText> : null}
          </MenuInfo>
          <MenuItems>
            {headerTopMenuItemList.map((navigationItem) => (
              <MenuItem
                key={navigationItem.link}
                isDisabled={
                  selectedMenuItem === (navigationItem.link as string)
                }
                isHighlighted={selectedMenuItem.startsWith(
                  navigationItem.link as string
                )}
              >
                <Link
                  to={navigationItem.link}
                  target={navigationItem.isNewTab ? '_blank' : '_self'}
                >
                  {navigationItem.label}
                </Link>
              </MenuItem>
            ))}
          </MenuItems>
        </Content>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  border-bottom: 1px solid ${colors.gray100};
  background: ${colors.white};
`;

const Content = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuInfo = styled.div`
  display: flex;
`;

const PhoneNumberText = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-right: 15px;
`;

const WorkTimeText = styled.span`
  display: block;
  font-size: 13px;
  line-height: 20px;
  color: ${colors.gray500};
`;

const MenuItems = styled.ul``;

const MenuItem = styled.li<{ isHighlighted?: boolean; isDisabled?: boolean }>`
  margin-right: 25px;
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.gray500};
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${colors.primary};
  }

  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          color: ${colors.primary};
          &:hover {
            color: ${colors.primary100};
          }
        `
      : null}

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          cursor: default;
          a {
            cursor: default;
          }
          &:hover {
            color: ${colors.primary};
          }
        `
      : null}
`;

export default TopSamovarkin;
