import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ViberIcon } from '@/assets/svg/social/viber.svg';
import { normalizePhoneNumber } from '@/utils/common';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { PhoneLink, Comment } from '../PhoneNumber.style';
import { PhoneNumberVariant } from '../PhoneNumber.types';

type Props = {
  variant: PhoneNumberVariant;
  phoneNumber: string;
  text?: string;
  withIcon?: boolean;
  className?: string;
};

function PhoneNumberViber({
  phoneNumber,
  variant,
  text,
  withIcon,
  className,
}: Props) {
  const href = `viber://chat?number=${normalizePhoneNumber(phoneNumber)}`;
  return (
    <PhoneNumber variant={variant} href={href} className={className}>
      <PhoneNumberWithIcon>
        {withIcon ? (
          <IconWrapper>
            <ViberIcon />
            Viber
          </IconWrapper>
        ) : null}
        {phoneNumber}
      </PhoneNumberWithIcon>
      {text ? <Comment>{text}</Comment> : null}
    </PhoneNumber>
  );
}

const PhoneNumber = styled(PhoneLink)`
  display: flex;
  &:hover {
    color: ${colors.violet};
  }

  svg {
    margin-right: 8px;
  }
  ${media.mobileLarge(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const PhoneNumberWithIcon = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  color: ${colors.violet};
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export default PhoneNumberViber;
