import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ThankIcon } from '@/assets/svg/thank.svg';
import { colors } from '@/constants/theme';

type Props = {
  title: string;
  text: string;
  textNote?: string;
};

function SuccessForm({ title, text, textNote }: Props) {
  return (
    <Container>
      <IconWrapper>
        <ThankIcon />
      </IconWrapper>
      <Title>{title}</Title>
      {textNote ? <TextNote>{textNote}</TextNote> : null}
      <Text>{text}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  padding: 40px 50px;
`;

const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  margin: 0 auto 30px;
  svg {
    fill: ${colors.primary};
  }
`;

const Title = styled.span`
  font-size: 21px;
  display: block;
  font-weight: 500;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-top: 15px;
`;

const TextNote = styled(Text)`
  margin-top: 20px;
`;

export default SuccessForm;
