import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import useSettingItem from '@/hooks/useSettingItem';
import { getParamAsString } from '@/utils/common';
import { ReactComponent as ArrowIcon } from '@/assets/svg/dropdown_arrow.svg';
import { ReactComponent as PhoneIcon } from '@/assets/svg/phone-2.svg';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import ContentContainer from '@/components/ContentContainer';
import PhoneNumber from '@/components/PhoneNumber';

function TopBelsex() {
  const router = useRouter();
  const locationLink = useSettingItem('HEADER_LOCATION_BUTTON_URL');
  const phoneNumberA1 = useSettingItem('CONTACTS_PHONE_A1');

  const headerTopMenuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'top')) ?? [];

  const selectedMenuItem = getParamAsString(router.asPath);

  return (
    <Container>
      <ContentContainer>
        <Inner>
          {phoneNumberA1 ? (
            <PhoneNumberWrapper>
              <PhoneIcon />
              <StyledPhoneNumber
                operator="a1"
                phoneNumber={phoneNumberA1}
                variant="small-bold"
                withIcon={false}
              />
            </PhoneNumberWrapper>
          ) : null}
          <MenuItems>
            {headerTopMenuItemList.map((navigationItem) => (
              <MenuItem
                key={navigationItem.link}
                isDisabled={
                  selectedMenuItem === (navigationItem.link as string)
                }
                isHighlighted={selectedMenuItem.startsWith(
                  navigationItem.link as string
                )}
              >
                <Link
                  to={navigationItem.link}
                  target={navigationItem.isNewTab ? '_blank' : '_self'}
                >
                  {navigationItem.label}
                </Link>
              </MenuItem>
            ))}
          </MenuItems>
          <LocationLink to={locationLink}>
            Минск
            <ArrowIcon />
          </LocationLink>
        </Inner>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.violet200};
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const StyledPhoneNumber = styled(PhoneNumber)`
  font-weight: 400;
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 20px;
  svg {
    transition: 0.3s;
    margin-right: 5px;
  }
  &:hover ${StyledPhoneNumber} {
    color: ${colors.white};
    opacity: 0.7;
  }
  &:hover {
    svg {
      opacity: 0.7;
    }
  }
`;

const MenuItems = styled.ul``;

const MenuItem = styled.li<{ isHighlighted?: boolean; isDisabled?: boolean }>`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-right: 40px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 0.7;
  }

  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          color: ${colors.primary};
          &:hover {
            color: ${colors.primary100};
          }
        `
      : null}

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          cursor: default;
          a {
            cursor: default;
          }
          &:hover {
            color: ${colors.primary};
          }
        `
      : null}
`;

const LocationLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 18px;
  svg {
    width: 10px;
    fill: ${colors.white};
    margin-left: 5px;
    &:hover {
      opacity: 0.7;
    }
  }
  &:hover {
    opacity: 0.7;
  }
`;

export default TopBelsex;
