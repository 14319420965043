import React from 'react';
import styled from 'styled-components';

import Bottom from '../components/Bottom';

import TopSamovarkin from './components/TopSamovarkin';
import MiddleSamovarkin from './components/MiddleSamovarkin';

function HeaderSamovarkin() {
  return (
    <HeaderContainer>
      <TopSamovarkin />
      <MiddleSamovarkin />
      <Bottom />
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header``;

export default HeaderSamovarkin;
