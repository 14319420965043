import React, { useState } from 'react';
import styled from 'styled-components';

import { ModalProps } from '@tager/web-components';

import ModalBox, { ModalCloseButton } from '@/components/ModalBox';
import SuccessForm from '@/components/form/SuccessForm';

import FeedbackForm from '../FeedbackForm';

type Props = ModalProps;

function FooterFeedbackForm({ closeModal }: Props) {
  const [isFeedbackFormClosed, setFeedbackFormClosed] = useState<boolean>(
    false
  );

  return (
    <ModalBox width={650}>
      <Inner>
        <ModalCloseButton onClose={closeModal} />
        {isFeedbackFormClosed ? (
          <SuccessForm
            title="Сообщение отправлено"
            textNote="Мы благодарим вас за обращение"
            text="В ближайшее время руководство прочитает ваше сообщение, и если будет необходимо, мы свяжемся с вами по указанному телефону"
          />
        ) : (
          <FeedbackForm
            setFeedbackFormClosed={() => setFeedbackFormClosed(true)}
          />
        )}
      </Inner>
    </ModalBox>
  );
}

const Inner = styled.div`
  position: relative;
  min-width: 450px;
  max-height: 90vh;
`;

export default FooterFeedbackForm;
