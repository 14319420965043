import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MtsIcon } from '@/assets/svg/social/mts.svg';
import { colors } from '@/constants/theme';
import { normalizePhoneNumber } from '@/utils/common';

import { PhoneLink, Comment } from '../PhoneNumber.style';
import { PhoneNumberVariant } from '../PhoneNumber.types';

type Props = {
  variant: PhoneNumberVariant;
  phoneNumber: string;
  text?: string;
  withIcon?: boolean;
  className?: string;
};

function PhoneNumberMts({
  phoneNumber,
  variant,
  text,
  withIcon,
  className,
}: Props) {
  const href = `tel:${normalizePhoneNumber(phoneNumber)}`;
  return (
    <PhoneNumber variant={variant} href={href} className={className}>
      {withIcon ? <MtsIcon /> : null}
      {phoneNumber}
      {text ? <Comment>{text}</Comment> : null}
    </PhoneNumber>
  );
}

const PhoneNumber = styled(PhoneLink)`
  &:hover {
    color: ${colors.red};
  }

  svg {
    margin-right: 8px;
  }
`;

export default PhoneNumberMts;
