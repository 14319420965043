import React from 'react';

import { LinkToPropType } from '@tager/web-components';
import { createLinkComponent } from '@tager/web-components';

export const linkConverter = (link: LinkToPropType) => link;

const TagerLink = createLinkComponent({ converter: linkConverter });

const isLinkForOldWeb = (link: string | any): boolean => {
  return false;
  /*
  if (process.env.NEXT_PUBLIC_THEME !== 'belmebel') return false;
  const linkStr = typeof link === 'string' ? link : link.as;
  return linkStr.startsWith('/products');*/
};

const isLinkReplacingEnabled = (): boolean =>
  process.env.NEXT_PUBLIC_ENV === 'production';

const Link = (props: any) =>
  isLinkReplacingEnabled() && isLinkForOldWeb(props.to) ? (
    <a href={props.to} {...props}>
      {props.children}
    </a>
  ) : (
    <TagerLink {...props} />
  );

export default Link;
