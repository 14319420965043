import React from 'react';
import styled from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { getStockLabel, normalizePrice } from '@/utils/common';
import { AvailabilityTypes, ProductFieldsType } from '@/typings/model';
import { StockVariant, StringFieldType } from '@/typings/common';
import { Picture } from '@/components/Picture';
import Placeholder from '@/assets/images/placeholder.png';
import {
  convertLabel,
  variantCssMap,
} from '@/components/ProductCard/ProductCard.constants';

import ProductBadge from './components/ProductBadge';
import ProductCardParams from './components/ProductCardParams';

export type ProductCardBigProps = {
  name: string;
  image: Nullable<ThumbnailType>;
  subtitle?: StringFieldType;
  badge?: StringFieldType;
  price: Nullable<number>;
  brand: Nullable<string>;
  discountPrice: Nullable<number>;
  availability: Nullable<AvailabilityTypes>;
  monthPayment: Nullable<number>;
  stockStatus?: Nullable<StockVariant>;
  fields: Array<ProductFieldsType>;
};

function ProductCardBig({
  image,
  name,
  subtitle,
  badge,
  brand,
  price,
  discountPrice,
  availability,
  monthPayment,
  stockStatus,
  fields,
}: ProductCardBigProps) {
  return (
    <Container>
      <MainWrapper>
        {badge ? (
          <BadgeWrapper>
            <ProductBadge text={badge} />
          </BadgeWrapper>
        ) : null}

        <ImageWrapper>
          {image ? (
            <Picture
              {...convertThumbnailToPictureProps(image)}
              alt={name}
              useSpinner
            />
          ) : (
            <Picture src={Placeholder} />
          )}
        </ImageWrapper>

        <TitleWrapper>
          <Title> {name} </Title>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        </TitleWrapper>

        {!price || stockStatus === 'NO' ? null : (
          <PriceWrapper>
            {discountPrice !== price && discountPrice ? (
              <OldPrice>{normalizePrice(price)} руб.</OldPrice>
            ) : null}
            <Wrapper>
              <Price>{normalizePrice(discountPrice ?? price)} руб.</Price>
              {monthPayment ? (
                <Credit>
                  {normalizePrice(monthPayment)}
                  {' руб. / мес.'}
                </Credit>
              ) : null}
            </Wrapper>
          </PriceWrapper>
        )}

        <Availability>
          {availability && (
            <AvailabilityLabel color={availability}>
              {convertLabel(availability)}
            </AvailabilityLabel>
          )}
        </Availability>

        {stockStatus ? (
          <ProductStock isStock={stockStatus}>
            {getStockLabel(stockStatus)}
          </ProductStock>
        ) : null}
      </MainWrapper>
      {!!fields.length ? (
        <ParamsWrapper>
          <StyledProductParams brand={brand} value={fields} dataMode="SHORT" />
        </ParamsWrapper>
      ) : null}
    </Container>
  );
}

const TitleWrapper = styled.div`
  white-space: normal;
  margin-top: 10px;
`;

const Availability = styled.span`
  display: block;
  margin-top: 20px;
  min-height: 18.4px;
`;

const AvailabilityLabel = styled.span<{ color: AvailabilityTypes }>`
  font-size: 14px;
  font-weight: 500;
  ${({ color }) => variantCssMap[color]}
`;

const Title = styled.span`
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
`;

const MainWrapper = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex: 1 0;
`;

const Subtitle = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.gray300};
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${colors.white};
  border: 1px solid ${colors.gray100};
  transition: 0.3s;
  padding: 15px 15px 0 15px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  }

  &:hover ${Title} {
    color: ${colors.primary};
  }
`;

const ImageWrapper = styled.div`
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 190px;
  }
`;

const BadgeWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const PriceWrapper = styled.div`
  margin-top: auto;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const OldPrice = styled.span`
  color: ${colors.gray300};
  font-weight: 500;
  text-decoration: line-through;
  display: block;
  margin-bottom: 2px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Price = styled.span`
  font-size: 22px;
  line-height: 100%;
  font-weight: 600;
`;

const Credit = styled.span`
  font-size: 13px;
  line-height: 20px;
  margin-left: 10px;
`;

const ProductStock = styled.span<{ isStock: StockVariant | null }>`
  display: block;
  font-size: 14px;
  color: ${(props) =>
    props.isStock === 'SHOP' || props.isStock === 'IN_SHOP'
      ? colors.primary
      : props.isStock === 'STOCK'
      ? colors.green
      : colors.black};
  font-weight: 500;
  margin-top: 15px;
`;

const ParamsWrapper = styled.div`
  border-top: 1px solid ${colors.gray100};
  margin: auto -15px 0;
  padding-bottom: 15px;
`;

const StyledProductParams = styled(ProductCardParams)`
  margin: 0 15px;
`;

export default ProductCardBig;
