import styled, { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';

import { ColorType } from './TextWithIcon.types';

const DefaultColorMixin = css`
  svg {
    fill: ${colors.primary};
  }
  &:hover {
    color: ${colors.primary};
    svg {
      path {
        fill: ${colors.primary};
      }
    }
  }
`;

const PrimaryColorMixin = css`
  color: ${colors.primary};
  svg {
    fill: ${colors.primary};
  }
  &:hover {
    color: ${colors.primary100};
    svg {
      fill: ${colors.primary100};
    }
  }
`;

const WhiteColorMixin = css`
  color: ${colors.white};
  svg {
    fill: ${colors.white};
  }
  &:hover {
    opacity: 0.7;
  }
`;

export function getColor(color?: ColorType): CssSnippet {
  switch (color) {
    case 'primary':
      return PrimaryColorMixin;
    case 'white':
      return WhiteColorMixin;
    default:
      return DefaultColorMixin;
  }
}

export const TextLink = styled(Link)<{
  color?: ColorType;
}>`
  cursor: pointer;
  ${(props) => getColor(props.color)};
`;

export const Text = styled.div<{
  bold?: boolean;
}>`
  display: inline-flex;
  font-size: 16px;
  align-items: center;
  white-space: pre-line;
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  svg {
    transition: 0.3s;
    margin-right: 10px;
  }
`;
