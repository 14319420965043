import React from 'react';
import styled from 'styled-components';

import Top from './components/Top';
import Middle from './components/Middle';
import Bottom from './components/Bottom';

function HeaderBelmebel() {
  return (
    <HeaderContainer>
      <Top />
      <Middle />
      <Bottom />
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header``;

export default HeaderBelmebel;
