import React from 'react';

import { Nullable } from '@tager/web-core';

import { ProductFieldsType } from '@/typings/model';

import ProductCardParamsBasicShort from './components/ProductCardParamsBasicShort';
import ProductCardParamsBasicFull from './components/ProductCardParamsBasicFull';

type Props = {
  value: Array<ProductFieldsType>;
  brand: Nullable<string>;
  dataMode: 'SHORT' | 'FULL';
  className?: string;
};

function ProductCardParams({ value, brand, dataMode, className }: Props) {
  switch (dataMode) {
    case 'SHORT':
      return (
        <ProductCardParamsBasicShort
          brand={brand}
          fields={value}
          className={className}
        />
      );
    case 'FULL':
      return (
        <ProductCardParamsBasicFull
          brand={brand}
          fields={value}
          className={className}
        />
      );
    default:
      return null;
  }
}

export default ProductCardParams;
