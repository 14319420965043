import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { CatalogMenuType } from '@/typings/model';
import { PlainPicture } from '@/components/Picture';

type Props = {
  activeCatalogCategory: CatalogMenuType;
};

function FirstSubcategories({ activeCatalogCategory }: Props) {
  if (
    !activeCatalogCategory.children.header ||
    !activeCatalogCategory.children.header.length
  )
    return null;

  return (
    <Container>
      {activeCatalogCategory.children.header.map((category, index) => {
        return (
          <StyledLink key={category.label + index} to={category.url}>
            <IconWrapper>
              {category.icon ? (
                <PlainPicture
                  {...convertThumbnailToPictureProps(category.icon)}
                />
              ) : null}
            </IconWrapper>
            <SubcategoriesText>{category.label}</SubcategoriesText>
          </StyledLink>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.gray};
  height: 150px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  color: ${colors.black};
  padding: 0 30px;

  ${media.laptop(
    css`
      padding: 0 20px;
      height: 130px;
    `
  )}
`;

const SubcategoriesText = styled.span`
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
  white-space: normal;
  margin-top: 10px;
  ${media.laptop(
    css`
      font-size: 12px;
    `
  )}
`;

const StyledLink = styled(Link)`
  padding: 0 30px;
  min-width: 50px;
  &:hover ${SubcategoriesText} {
    color: ${colors.primary};
  }
  ${media.laptop(css`
    padding: 0 20px;
  `)}

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  img {
    height: 60px;
    width: 100px;
  }

  ${media.laptop(
    css`
      height: 60px;
      img {
        height: 50px;
      }
    `
  )}
`;

export default FirstSubcategories;
