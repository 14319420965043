import React from 'react';

import {
  createMediaQuery,
  createPictureComponent,
  PictureMediaQueryItemType,
  createPlainPictureComponent,
  PlainPictureProps,
  PictureProps,
} from '@tager/web-components';

import { breakpoints } from '@/constants/theme';
import Spinner from '@/components/Spinner';

export type PictureMediaQueryType =
  | 'mobileSmall'
  | 'mobileLarge'
  | 'tabletSmall'
  | 'tabletLarge'
  | 'laptop'
  | 'desktop';

const MEDIA_QUERY_LIST: Array<PictureMediaQueryItemType<
  PictureMediaQueryType
>> = [
  { name: 'desktop', value: createMediaQuery({ min: breakpoints.desktop }) },
  { name: 'laptop', value: createMediaQuery({ min: breakpoints.laptop }) },
  {
    name: 'tabletLarge',
    value: createMediaQuery({ min: breakpoints.tabletLarge }),
  },
  {
    name: 'tabletSmall',
    value: createMediaQuery({ min: breakpoints.tabletSmall }),
  },
  { name: 'mobileLarge', value: createMediaQuery({ min: 480 }) },
  {
    name: 'mobileSmall',
    value: createMediaQuery({ min: breakpoints.mobileSmall }),
  },
];

const BasePlainPicture = createPlainPictureComponent({
  mediaQueryList: MEDIA_QUERY_LIST,
});

export function PlainPicture({
  src,
  ...props
}: PlainPictureProps<PictureMediaQueryType>) {
  return (
    <BasePlainPicture {...props} src={src?.replace('http://', 'https://')} />
  );
}

const BasePicture = createPictureComponent({
  mediaQueryList: MEDIA_QUERY_LIST,
});

export function Picture({
  src,
  ...props
}: PictureProps<PictureMediaQueryType>) {
  return (
    <BasePicture
      {...props}
      src={src?.replace('http://', 'https://')}
      spinnerComponent={Spinner}
    />
  );
}
