import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  width?: number;
  children: React.ReactNode;
};

function ModalBox({ children, width }: Props) {
  return <Inner width={width}>{children}</Inner>;
}

const Inner = styled.div<{ width?: number }>`
  background: ${colors.white};
  z-index: 11;
  width: min(${(props) => (props.width ? `${props.width}px` : `600px`)}, 90vw);
  overflow: auto;
`;

export default ModalBox;
