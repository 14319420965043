import React from 'react';

import { getCurrentProject } from '@/utils/common';

import {
  HeaderBelmebel,
  HeaderBelsex,
  HeaderBelkids,
  HeaderSamovarkin,
} from './HeaderProject';

function Header() {
  const project = getCurrentProject();

  switch (project) {
    case 'BELMEBEL':
      return <HeaderBelmebel />;
    case 'BELSEX':
      return <HeaderBelsex />;
    case 'BELKIDS':
      return <HeaderBelkids />;
    case 'SAMOVARKIN':
      return <HeaderSamovarkin />;
    default:
      return <HeaderBelmebel />;
  }
}

export default Header;
