import styled from 'styled-components';

const ContentContainer = styled.div`
  max-width: calc(100% - 30px);
  width: 1710px;
  margin: 0 auto;
  padding: 0 15px;
`;

export default ContentContainer;
