import React from 'react';

import { StringFieldType } from '@/typings/common';

import PhoneNumberA1 from './components/PhoneNumberA1';
import PhoneNumberMts from './components/PhoneNumberMts';
import PhoneNumberViber from './components/PhoneNumberViber';
import { PhoneNumberVariant, PhoneOperatorType } from './PhoneNumber.types';

type Props = {
  operator: PhoneOperatorType;
  phoneNumber: StringFieldType;
  withIcon?: boolean;
  variant: PhoneNumberVariant;
  text?: string;
  className?: string;
};

function PhoneNumber({
  operator,
  phoneNumber,
  variant,
  withIcon = true,
  ...rest
}: Props) {
  if (!phoneNumber) return null;
  switch (operator) {
    case 'a1':
      return (
        <PhoneNumberA1
          phoneNumber={phoneNumber}
          variant={variant}
          withIcon={withIcon}
          {...rest}
        />
      );
    case 'mts':
      return (
        <PhoneNumberMts
          phoneNumber={phoneNumber}
          variant={variant}
          withIcon={withIcon}
          {...rest}
        />
      );
    case 'viber':
      return (
        <PhoneNumberViber
          phoneNumber={phoneNumber}
          variant={variant}
          withIcon={withIcon}
          {...rest}
        />
      );
    default:
      return null;
  }
}

export default PhoneNumber;
