import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from '@tager/web-core';

import { colors } from '@/constants/theme';
import SuccessForm from '@/components/form/SuccessForm';
import { ModalCloseButton } from '@/components/ModalBox';

import CallForm from '../CallForm';

type HeaderCallFormProps = {
  onClose: () => void;
};

const HeaderCallForm: React.FC<HeaderCallFormProps> = ({ onClose }) => {
  const [isCallFormClosed, setCallFormClosed] = useState<boolean>(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => onClose());
  return (
    <Container ref={ref}>
      <ModalCloseButton onClose={onClose} />
      {isCallFormClosed ? (
        <SuccessForm
          title="Заявка отправлена"
          text="Мы свяжемся с вами по указанному телефону"
        />
      ) : (
        <CallForm setCallFormClosed={() => setCallFormClosed(true)} />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  background: ${colors.white};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  z-index: 11;
  width: 400px;
  margin-top: 15px;
  height: 330px;

  &:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 11px 11px;
    border-color: transparent transparent ${colors.white} transparent;
    position: absolute;
    content: '';
    display: block;
    top: -11px;
    left: 34px;
  }
`;

export default HeaderCallForm;
