import React from 'react';
import styled from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { getCurrentProject } from '@/utils/common';
import { ReactComponent as AdultsModeIcon } from '@/assets/svg/adultsMode.svg';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

function Bottom() {
  const copyright = useSettingItem('FOOTER_COPYRIGHT');
  const footerLinks = useSettingItem('FOOTER_LINKS');
  const project = getCurrentProject();

  return (
    <Container>
      <Wrapper>
        <Text>{copyright}</Text>
        {project === 'BELMEBEL' || project === 'BELKIDS' ? (
          <TagerLink to="https://ozitag.com/tager" target="_blank">
            Работает на <b>TAGER</b>
          </TagerLink>
        ) : null}
        {project === 'BELSEX' ? (
          <Wrapper>
            <IconWrapper>
              <AdultsModeIcon />
            </IconWrapper>
            <Text>На сайте присутствуют материалы для взрослых</Text>
          </Wrapper>
        ) : null}
      </Wrapper>
      {footerLinks && !!footerLinks.length
        ? footerLinks.map((link) => (
            <StyledLink to={link.link} key={link.link}>
              {link.label}
            </StyledLink>
          ))
        : null}
    </Container>
  );
}

const Container = styled.div`
  height: 80px;
  padding: 25px 0 15px;
  font-size: 13px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  font-size: 13px;
  line-height: 22px;
`;

const TagerLink = styled(Link)`
  margin-right: 100px;
  &:hover {
    b {
      color: ${colors.primary};
    }
  }
`;

const IconWrapper = styled.div`
  svg {
    margin-right: 5px;
  }
`;

const StyledLink = styled(Link)`
  margin-right: 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition: 0.3s all ease;
  &:hover {
    color: ${colors.primary};
    border-bottom-color: transparent;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export default Bottom;
