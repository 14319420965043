import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';

type Props = {
  tabs: Array<{ name: string; value: string }>;
  activeTab: string;
  setActiveTab: (value: string) => void;
  variant: 'big' | 'small';
  className?: string;
};

function TabsBlock({
  variant,
  activeTab,
  setActiveTab,
  tabs,
  className,
}: Props) {
  return (
    <Tabs className={className}>
      <ContentContainer>
        {tabs.map((tab) => (
          <TabButton
            key={tab.name}
            variant={variant}
            onClick={() => setActiveTab(tab.value)}
            isActive={activeTab === tab.value}
          >
            {tab.name}
          </TabButton>
        ))}
      </ContentContainer>
    </Tabs>
  );
}

const Tabs = styled.div`
  background: ${colors.gray};
  display: flex;
  align-items: center;
`;

const TabButton = styled.button<{
  isActive?: boolean;
  variant: 'big' | 'small';
}>`
  position: relative;

  color: ${(props) => (props.isActive ? colors.primary : colors.black)};
  ${(props) =>
    props.variant === 'big'
      ? css`
          font-size: 18px;
          margin-right: 75px;
          line-height: 55px;
          font-weight: 600;
        `
      : css`
          font-size: 14px;
          margin-right: 35px;
          line-height: 40px;
          font-weight: 500;
        `};

  &:hover {
    color: ${colors.primary};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    left: 0;
    right: 0;
    background: ${colors.primary};
    display: ${(props) => (props.isActive ? 'block' : 'none')};
  }
`;

export default TabsBlock;
