import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { useSelector } from 'react-redux';

import { colors } from '@/constants/theme';
import { ReactComponent as ChatIcon } from '@/assets/svg/chat.svg';
import { ReactComponent as ChatOfficeIcon } from '@/assets/svg/chat_office.svg';
import useSettingItem from '@/hooks/useSettingItem';
import MessengerIcon from '@/components/MessengerIcon';
import Spinner from '@/components/Spinner';
import { selectFilterValue } from '@/store/reducers/filter';

class MessengersManager {
  isHidden: boolean;

  constructor() {
    this.isHidden = true;
  }

  setHidden(value: boolean) {
    this.isHidden = value;
  }
}

const messengersManager = new MessengersManager();

function Messengers() {
  const telegram = useSettingItem('CONTACTS_TELEGRAM');
  const whatsapp = useSettingItem('CONTACTS_WHATSAPP');
  const viber = useSettingItem('CONTACTS_VIBER');
  const widgetId = useSettingItem('SERVICE_JIVOSITE_WIDGET_ID');
  const [isJivoInitialized, setJivoInitialized] = useState<boolean>(true);
  const [isJivoHidden, setJivoHidden] = useState<boolean>(true);
  const isJivoOpened = useRef<boolean>(false);
  const [isHidden, setHidden] = useState(messengersManager.isHidden);
  const isVerticalFilterOpened = useSelector(selectFilterValue);

  function handleHiddenChange(newValue: boolean) {
    setHidden(newValue);
    messengersManager.setHidden(newValue);
  }

  function toggleHidden() {
    handleHiddenChange(!isHidden);
  }

  useEffect(() => {
    window.jivo_onLoadCallback = function () {
      setJivoInitialized(true);
    };

    window.jivo_onOpen = function () {
      setJivoHidden(false);
    };

    window.jivo_onChangeState = function (state: string) {
      if (!isJivoOpened.current) {
        isJivoOpened.current = true;
      } else {
        setJivoHidden(state === 'label');
      }
    };
  }, [isJivoHidden, setJivoHidden, setJivoInitialized]);

  function changeJivo() {
    toggleHidden();
    setJivoHidden((prev) => !prev);
    try {
      window.jivo_api?.open({ start: 'chat' });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container
      isVerticalFilterOpened={isVerticalFilterOpened}
      isHidden={isJivoHidden}
    >
      <Main>
        <MainButton onClick={toggleHidden} isActive={!isHidden}>
          {isHidden ? <ChatIcon /> : null}
        </MainButton>
      </Main>
      <Body isHidden={isHidden}>
        {widgetId ? (
          <MessengerItem>
            <ChatButton
              isJivoInitialized={isJivoInitialized}
              onClick={isJivoInitialized ? changeJivo : undefined}
            >
              <OverlayIcon isJivoInitialized={isJivoInitialized}>
                <ChatOfficeIcon />
              </OverlayIcon>
              {!isJivoInitialized ? (
                <Spinner absolute={false} color="gray" />
              ) : null}
            </ChatButton>
          </MessengerItem>
        ) : null}
        {telegram ? (
          <MessengerItem>
            <MessengerIcon messenger="telegram" phoneOrChannel={telegram} />
          </MessengerItem>
        ) : null}
        {viber ? (
          <MessengerItem>
            <MessengerIcon messenger="viber" phoneOrChannel={viber} />
          </MessengerItem>
        ) : null}
        {whatsapp ? (
          <MessengerItem>
            <MessengerIcon messenger="whatsapp" phoneOrChannel={whatsapp} />
          </MessengerItem>
        ) : null}
      </Body>
      <JivoGlobalStyle isHidden={isJivoHidden} />
    </Container>
  );
}

const JivoGlobalStyle = createGlobalStyle<{ isHidden: boolean }>`
  body>jdiv {
    display: ${({ isHidden }) => (isHidden ? 'none' : 'inline')};
  }
`;

const Container = styled.div<{
  isVerticalFilterOpened: boolean;
  isHidden: boolean;
}>`
  position: fixed;
  bottom: 10px;
  z-index: 2001;
  ${({ isVerticalFilterOpened }) =>
    isVerticalFilterOpened
      ? css`
          left: 15px;
        `
      : css`
          right: 15px;
        `};

  ${(props) =>
    !props.isHidden && props.isVerticalFilterOpened
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `};
`;

const Main = styled.div``;

const mainButtonMixin = css`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
`;

const buttonLineMixin = css`
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background-color: #efefef;
  opacity: 0;
  visibility: hidden;
  transition: 0.45s ease;
`;

const MainButton = styled.button<{ isActive: boolean }>`
  transition: 0.3ms ease;
  background-color: ${colors.primary};
  ${mainButtonMixin};

  &:before {
    transform: translate(-50%, -50%) rotate(45deg) translateZ(0);
    ${buttonLineMixin};
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg) translateZ(0);
    ${buttonLineMixin};
  }

  ${({ isActive }) =>
    isActive
      ? css`
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);

          &:before {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, -50%) rotate(315deg) translateZ(0);
          }

          &:after {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, -50%) rotate(225deg) translateZ(0);
          }
        `
      : css`
          box-shadow: 0 2px 10px 0 transparent;
        `}
`;

const Body = styled.div<{ isHidden: boolean }>`
  position: absolute;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  transition: 0.4s ease;

  ${({ isHidden }) =>
    isHidden
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `};
`;

const MessengerItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &:first-child {
    margin-top: 5px;
  }
`;

const ChatButton = styled.button<{ isJivoInitialized: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.3s;
  ${({ isJivoInitialized }) =>
    isJivoInitialized
      ? css`
          &:hover {
            transform: scale(1.1);
          }

          svg {
            width: 30px;
            height: 25px;
          }
        `
      : css`
          cursor: default;
        `};};
`;

const OverlayIcon = styled.div<{ isJivoInitialized: boolean }>`
  opacity: ${({ isJivoInitialized }) => (isJivoInitialized ? '1' : '0')};
  transition: all 0.3s ease-in-out;
  height: 25px;
`;

export default Messengers;
