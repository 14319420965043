import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import { ProductFieldsType } from '@/typings/model';

import { MainParam, ParamShort } from '../ParamsBasis.style';
import ParamBasis from '../ParamsBasis';

type ShortProps = {
  fields: Array<ProductFieldsType>;
  className?: string;
  brand: Nullable<string>;
};

function ProductCardParamsBasicShort({ fields, brand, className }: ShortProps) {
  const mechanismField = fields.find(
    (field) => field.name === 'MEHANIZM-TRANSFORMACII'
  );

  return (
    <Short className={className}>
      {brand && (
        <MainParam>
          <ParamBasis param={{ label: 'Производитель', value: brand }} />
        </MainParam>
      )}
      {mechanismField && (
        <MainParam>
          <ParamBasis param={mechanismField} />
        </MainParam>
      )}
      <ParamWrapper>
        {fields.map((field, index) =>
          !(field === mechanismField) ? (
            <ParamShort key={field.name ?? index}>
              <ParamBasis param={field} />
            </ParamShort>
          ) : null
        )}
      </ParamWrapper>
    </Short>
  );
}

const Short = styled.div`
  width: 230px;
`;

const ParamWrapper = styled.div`
  display: flex;
  margin: 10px 0 0;
`;

export default ProductCardParamsBasicShort;
