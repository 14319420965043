import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { getStockLabel, normalizePrice } from '@/utils/common';
import { media } from '@/utils/mixin';
import { AvailabilityTypes, ProductFieldsType } from '@/typings/model';
import { StockVariant, StringFieldType } from '@/typings/common';
import { Picture } from '@/components/Picture';
import Placeholder from '@/assets/images/placeholder.png';
import {
  convertLabel,
  variantCssMap,
} from '@/components/ProductCard/ProductCard.constants';

import ProductBadge from './components/ProductBadge';
import ProductCardParams from './components/ProductCardParams';

export type ProductCardHorizontalProps = {
  name: string;
  image: Nullable<ThumbnailType>;
  subtitle?: StringFieldType;
  badge?: StringFieldType;
  price: Nullable<number>;
  brand: Nullable<string>;
  availability: Nullable<AvailabilityTypes>;
  discountPrice: Nullable<number>;
  monthPayment: Nullable<number>;
  stockStatus?: Nullable<StockVariant>;
  fields: Array<ProductFieldsType>;
};

function ProductCardHorizontal({
  image,
  name,
  subtitle,
  badge,
  brand,
  price,
  availability,
  discountPrice,
  monthPayment,
  stockStatus,
  fields,
}: ProductCardHorizontalProps) {
  return (
    <Container>
      <ImageWrapper>
        {image ? (
          <Picture
            {...convertThumbnailToPictureProps(image)}
            alt={name}
            useSpinner
          />
        ) : (
          <Picture src={Placeholder} />
        )}
        {badge ? (
          <BadgeWrapper>
            <ProductBadge text={badge} />
          </BadgeWrapper>
        ) : null}
      </ImageWrapper>

      <InfoWrapper>
        <TitleWrapper>
          <Title> {name} </Title>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        </TitleWrapper>

        {!!fields.length ? (
          <StyledProductParams brand={brand} value={fields} dataMode="FULL" />
        ) : null}
      </InfoWrapper>

      <PriceWrapper>
        {!price || stockStatus === 'NO' ? (
          stockStatus ? (
            <ProductStock isStock={stockStatus}>
              {getStockLabel(stockStatus)}
            </ProductStock>
          ) : null
        ) : (
          <>
            <Wrapper>
              {discountPrice !== price && discountPrice ? (
                <OldPrice>{normalizePrice(price)} руб.</OldPrice>
              ) : null}
              <Price>{normalizePrice(discountPrice ?? price)} руб.</Price>
            </Wrapper>

            {stockStatus ? (
              <ProductStock isStock={stockStatus}>
                {getStockLabel(stockStatus)}
              </ProductStock>
            ) : null}

            {monthPayment ? (
              <Credit>
                {`Рассрочка от ${normalizePrice(monthPayment)} руб. / мес.`}
              </Credit>
            ) : null}
            <Availability>
              {availability && (
                <AvailabilityLabel color={availability}>
                  {convertLabel(availability)}
                </AvailabilityLabel>
              )}
            </Availability>
          </>
        )}
      </PriceWrapper>
    </Container>
  );
}

const ImageWrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-height: 130px;
  }

  ${media.laptop(css`
    width: 250px;
  `)}
`;

const Availability = styled.span`
  display: block;
  margin-top: 20px;
  min-height: 18.4px;
`;

const AvailabilityLabel = styled.span<{ color: AvailabilityTypes }>`
  font-size: 14px;
  font-weight: 500;
  ${({ color }) => variantCssMap[color]}
`;

const BadgeWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: 0 20px;
`;

const TitleWrapper = styled.div``;

const Title = styled.span`
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
`;

const Subtitle = styled.span`
  font-size: 11px;
  font-weight: 500;
  color: ${colors.gray300};
`;

const StyledProductParams = styled(ProductCardParams)`
  margin-top: 20px;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  min-width: 225px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const OldPrice = styled.span`
  color: ${colors.gray500};
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 10px;
`;

const Price = styled.span`
  font-size: 26px;
  line-height: 100%;
  font-weight: 600;
`;

const ProductStock = styled.span<{ isStock: StockVariant | null }>`
  font-size: 13px;
  color: ${(props) =>
    props.isStock === 'SHOP' || props.isStock === 'IN_SHOP'
      ? colors.primary
      : props.isStock === 'STOCK'
      ? colors.green
      : colors.black};
  font-weight: 500;
  margin-top: 15px;
`;

const Credit = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: ${colors.primary};
  line-height: 35px;
  border: 1px solid ${colors.primary};
  border-radius: 5px;
  text-align: center;
  padding: 0 10px;
  margin-top: 15px;
`;

const Container = styled.div`
  width: 100%;
  min-height: 170px;
  display: flex;

  background: ${colors.white};
  border: 1px solid ${colors.gray100};
  transition: 0.3s;
  padding: 15px;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  }

  &:hover ${Title} {
    color: ${colors.primary};
  }
`;

export default ProductCardHorizontal;
