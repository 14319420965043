import React from 'react';
import styled from 'styled-components';

import A1Icon from '@/assets/images/a1.png';
import { colors } from '@/constants/theme';
import { normalizePhoneNumber } from '@/utils/common';

import { PhoneLink, Comment } from '../PhoneNumber.style';
import { PhoneNumberVariant } from '../PhoneNumber.types';

type Props = {
  variant: PhoneNumberVariant;
  phoneNumber: string;
  text?: string;
  withIcon?: boolean;
  className?: string;
};

function PhoneNumberA1({
  phoneNumber,
  variant,
  text,
  withIcon,
  className,
}: Props) {
  const href = `tel:${normalizePhoneNumber(phoneNumber)}`;
  return (
    <PhoneNumber variant={variant} href={href} className={className}>
      {withIcon ? <img src={A1Icon} alt="A1" /> : null}
      {phoneNumber}
      {text ? <Comment>{text}</Comment> : null}
    </PhoneNumber>
  );
}

const PhoneNumber = styled(PhoneLink)`
  &:hover {
    color: ${colors.red};
  }
  img {
    margin-right: 8px;
  }
`;

export default PhoneNumberA1;
