import React from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { useModal } from '@tager/web-components';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { queryAdded, selectQuery } from '@/store/reducers/pages/search';
import { media } from '@/utils/mixin';
import { normalizeThumbnail } from '@/utils/common';
import { ReactComponent as CartIcon } from '@/assets/svg/cart.svg';
import { ReactComponent as WishlistIcon } from '@/assets/svg/heart.svg';
import { ReactComponent as ComparisonIcon } from '@/assets/svg/comparison.svg';
import useSettingItem from '@/hooks/useSettingItem';
import { colors } from '@/constants/theme';
import SearchInput from '@/components/Input/SearchInput';
import Link from '@/components/Link';
import ContentContainer from '@/components/ContentContainer';
import { PlainPicture } from '@/components/Picture';

import SearchModal from '../../components/SearchModal';
import { ToppedOverlay, ContentWrapper, Logo } from '../../components/Middle';

function MiddleBelsex() {
  const router = useRouter();
  const openModal = useModal();
  const dispatch = useDispatch();
  const logo = useSettingItem('HEADER_LOGO');
  const logo2x = useSettingItem('HEADER_LOGO_2X');
  const searchQuery = useSelector(selectQuery);

  const OpenSearchModal = () =>
    openModal(
      SearchModal,
      {},
      { components: { Overlay: ToppedOverlay }, withAnimation: false }
    );

  const openModalOrPage = !!searchQuery.trim().length
    ? () => router.push(`/search?query=${searchQuery}`)
    : OpenSearchModal;

  const thumbnail = normalizeThumbnail(logo, logo2x);
  return (
    <Container>
      <ContentContainer>
        <ContentWrapper>
          {logo || logo2x ? (
            <Logo>
              <LogoLink to="/">
                <PlainPicture {...convertThumbnailToPictureProps(thumbnail)} />
              </LogoLink>
            </Logo>
          ) : null}

          <StyledSearchInput
            variant="primary"
            borderColor="none"
            placeholder="Поиск по сайту"
            value={searchQuery}
            onChange={(e) => {
              dispatch(queryAdded(e.target.value));
              OpenSearchModal();
            }}
            onKeyDown={(event) =>
              event.keyCode === 13 && !!searchQuery.trim().length
                ? router.push(`/search?query=${searchQuery}`)
                : OpenSearchModal
            }
            onClickButton={openModalOrPage}
          />

          <Icons>
            <IconLink to="/wishlist">
              <WishlistIcon />
              Избранное
            </IconLink>
            <IconLink to="/compare">
              <ComparisonIcon />
              Сравнение
            </IconLink>
            <IconLink to="/cart">
              <CartIcon />
              Корзина
            </IconLink>
          </Icons>
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.violet100};
`;

const LogoLink = styled(Link)`
  img {
    max-width: 250px;
    max-height: 80px;

    ${media.laptop(
      css`
        max-width: 195px;
      `
    )}
  }
`;

const StyledSearchInput = styled(SearchInput)`
  width: 750px;
  ${media.laptop(
    css`
      width: 650px;
    `
  )}
  input {
    &::placeholder {
      font-size: 14px;
      color: ${colors.violet100};
    }
  }
  svg {
    height: 16px;
    width: 16px;
    fill: ${colors.violet100};
    &:hover {
      fill: ${colors.violet100};
      opacity: 0.7;
    }
  }
`;

const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.5;
`;

const IconLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  margin-right: 55px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 0.7;
  }
  svg {
    width: 30px;
    height: 32px;
    padding-bottom: 5px;
    transition: all 0.3s ease-in-out;
    fill: ${colors.white};
    &:hover {
      opacity: 0.7;
    }
  }

  ${media.laptop(
    css`
      margin-right: 35px;
    `
  )}
`;

export default MiddleBelsex;
