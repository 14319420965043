import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Preloader } from '@tager/web-components';
import { scroller } from '@tager/web-core';

import { colors } from '@/constants/theme';
import AnnouncementBanner, {
  announcementElementId,
} from '@/components/Layout/components/AnnouncementBanner';
import AdultsMode from '@/components/Layout/components/AdultsMode';
import useSettingItem from '@/hooks/useSettingItem';
import { getCookie } from '@/utils/common';

import Header from './components/Header';
import Footer from './components/Footer';

type Props = {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
};

function Layout({ children, isPreloaderHidden }: Props) {
  const [adultsConfirmed, setAdultsConfirmed] = useState<boolean>(true);
  const adultsMode = useSettingItem('ADULTS_MODE_ENABLED');
  const [distanceToTop, setDistanceToTop] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (getCookie('adults_confirmed') !== 'true') {
      setAdultsConfirmed(false);
    }
  }, [adultsConfirmed]);

  useEffect(() => {
    if (adultsMode && !adultsConfirmed) {
      scroller.lock(ref.current);
    }

    return () => scroller.unlockAll();
  }, [adultsMode, adultsConfirmed]);

  useEffect(() => {
    setDistanceToTop(
      document.getElementById(announcementElementId)?.clientHeight ?? 0
    );
  }, []);

  return (
    <Container distanceToTop={distanceToTop}>
      <Preloader hidden={isPreloaderHidden} />
      <Header />
      <Main>{children}</Main>
      <Footer />
      <AnnouncementBanner />
      {adultsMode && !adultsConfirmed ? (
        <AdultsMode
          onAgree={() => {
            setAdultsConfirmed(true);
            document.cookie = 'adults_confirmed=true';
          }}
        />
      ) : null}
    </Container>
  );
}

const Container = styled.div<{ distanceToTop?: number }>`
  background-color: ${colors.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ distanceToTop }) =>
    distanceToTop ? `${distanceToTop}px` : 0};
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export default Layout;
