import React from 'react';

import { ReactComponent as SearchIcon } from '@/assets/svg/search.svg';

import * as S from './SearchInput.style';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  variant: 'default' | 'primary';
  onClickButton?: () => void;
  borderColor?: 'primary' | 'gray' | 'none';
  className?: string;
};

export type InputRef = HTMLInputElement;

function SearchInput(
  { className, variant, borderColor, onClickButton, ...rest }: Props,
  ref: React.Ref<InputRef>
) {
  return (
    <S.Container className={className}>
      <S.Input
        ref={ref}
        variant={variant}
        borderColor={borderColor}
        {...rest}
      />
      <S.SearchButton onClick={onClickButton}>
        <S.IconComponent as={SearchIcon} />
      </S.SearchButton>
    </S.Container>
  );
}

export default React.forwardRef<InputRef, Props>(SearchInput);
