import React, { useState } from 'react';
import styled from 'styled-components';

import { ModalProps } from '@tager/web-components';

import SuccessForm from '@/components/form/SuccessForm';
import ModalBox, { ModalCloseButton } from '@/components/ModalBox';

import CallForm from '../CallForm';

type Props = ModalProps;

function FooterCallForm({ closeModal }: Props) {
  const [isCallFormClosed, setCallFormClosed] = useState<boolean>(false);

  return (
    <ModalBox width={500}>
      <Inner>
        <ModalCloseButton onClose={closeModal} />
        {isCallFormClosed ? (
          <SuccessForm
            title="Заявка отправлена"
            text="Мы свяжемся с вами по указанному телефону"
          />
        ) : (
          <CallForm setCallFormClosed={() => setCallFormClosed(true)} />
        )}
      </Inner>
    </ModalBox>
  );
}

const Inner = styled.div`
  position: relative;
  min-width: 450px;
  max-height: 90vh;
`;

export default FooterCallForm;
