import React from 'react';
import styled from 'styled-components';

import { MenuItemType } from '@tager/web-modules';
import { useModal } from '@tager/web-components';

import { colors } from '@/constants/theme';
import useSettingItem from '@/hooks/useSettingItem';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { useTypedSelector } from '@/store/store';
import Link from '@/components/Link';
import { FooterFeedbackForm } from '@/components/form/FeedbackForm';
import PhoneNumber from '@/components/PhoneNumber';
import MessengerIcon from '@/components/MessengerIcon';
import TextWithIcon from '@/components/TextWithIcon';

function TopBelsex() {
  const phoneNumberA1 = useSettingItem('CONTACTS_PHONE_A1');
  const phoneNumberMts = useSettingItem('CONTACTS_PHONE_MTS');
  const workTime = useSettingItem('FOOTER_WORK_TIME');
  const email = useSettingItem('CONTACTS_EMAIL');
  const openModal = useModal();
  const vk = useSettingItem('SOCIAL_VKONTAKTE');
  const facebook = useSettingItem('SOCIAL_FACEBOOK');
  const instagram = useSettingItem('SOCIAL_INSTAGRAM');
  const youtube = useSettingItem('SOCIAL_YOUTUBE');

  const footerMenuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  return (
    <Container>
      {footerMenuItemList.map((navigationItem: MenuItemType) => (
        <Column key={navigationItem.id}>
          {navigationItem.link ? (
            <ColumnLink
              to={navigationItem.link}
              target={navigationItem.isNewTab ? '_blank' : '_self'}
            >
              {navigationItem.label}
            </ColumnLink>
          ) : (
            <Title>{navigationItem.label}</Title>
          )}
          <ul>
            {navigationItem.children.map((menuItem) => (
              <ColumnItem key={menuItem.id}>
                <Link
                  to={menuItem.link}
                  target={menuItem.isNewTab ? '_blank' : '_self'}
                >
                  {menuItem.label}
                </Link>
              </ColumnItem>
            ))}
          </ul>
        </Column>
      ))}

      <Column>
        <Title>Контакты</Title>
        <StyledPhoneNumber
          operator="a1"
          phoneNumber={phoneNumberA1}
          variant="small-bold"
          withIcon={false}
        />
        <StyledPhoneNumber
          operator="mts"
          phoneNumber={phoneNumberMts}
          variant="small-bold"
          withIcon={false}
        />
        {workTime ? <StyledTextWithIcon icon="clock" text={workTime} /> : null}
        {email ? (
          <StyledTextWithIcon
            icon="email"
            text={email}
            to={`mailto:${email}`}
            color="white"
          />
        ) : null}
      </Column>

      <Column>
        <Title>Обратная связь</Title>
        <CallButton onClick={() => openModal(FooterFeedbackForm, {})}>
          Заказать звонок
        </CallButton>
        <Text>Наш менеджер свяжется с вами</Text>
        <Icons>
          {instagram ? (
            <MessengerItem>
              <StyledMessengerIcon
                messenger="instagramBelsex"
                phoneOrChannel={instagram}
              />
            </MessengerItem>
          ) : null}
          {youtube ? (
            <MessengerItem>
              <StyledMessengerIcon
                messenger="youtubeBelsex"
                phoneOrChannel={youtube}
              />
            </MessengerItem>
          ) : null}
          {vk ? (
            <MessengerItem>
              <StyledMessengerIcon messenger="vkBelsex" phoneOrChannel={vk} />
            </MessengerItem>
          ) : null}
          {facebook ? (
            <MessengerItem>
              <StyledMessengerIcon
                messenger="facebookBelsex"
                phoneOrChannel={facebook}
              />
            </MessengerItem>
          ) : null}
        </Icons>
      </Column>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 65px 0 20px;
  line-height: 22px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  display: block;
  font-weight: 800;
  margin-bottom: 35px;
`;

const ColumnLink = styled(Link)`
  display: block;
  font-weight: 800;
  margin-bottom: 35px;
  &:hover {
    opacity: 0.7;
  }
`;

const ColumnItem = styled.li`
  display: block;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 400;
  &:hover {
    opacity: 0.7;
  }
  &:first-child {
    margin-top: 0;
  }
`;

const StyledPhoneNumber = styled(PhoneNumber)`
  &:first-of-type {
    margin-bottom: 15px;
  }
  &:hover {
    color: ${colors.white};
    opacity: 0.7;
  }
`;

const CallButton = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 7%);
  border: 1px solid ${colors.white};
  color: ${colors.white};
  transition: 0.3s;

  &:hover {
    background: transparent;
  }

  svg {
    fill: ${colors.white};
    margin-right: 10px;
  }
`;

const Text = styled.p`
  max-width: 250px;
  margin-top: 15px;
`;

const StyledTextWithIcon = styled(TextWithIcon)`
  margin-top: 15px;
  svg {
    fill: ${colors.white};
  }
`;

const Icons = styled.ul`
  display: flex;
  margin-top: 15px;
`;

const MessengerItem = styled.li`
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

const StyledMessengerIcon = styled(MessengerIcon)`
  height: 35px;
  width: 35px;
`;

export default TopBelsex;
