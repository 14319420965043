import React from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';

import Button from '@/components/Button';
import PhoneNumber from '@/components/PhoneNumber';
import TextWithIcon from '@/components/TextWithIcon';
import useSettingItem from '@/hooks/useSettingItem';
import { FooterCallForm } from '@/components/form/CallForm';

function Contact() {
  const phoneNumberA1 = useSettingItem('CONTACTS_PHONE_A1');
  const phoneNumberMts = useSettingItem('CONTACTS_PHONE_MTS');
  const workTime = useSettingItem('FOOTER_WORK_TIME');
  const email = useSettingItem('CONTACTS_EMAIL');
  const openModal = useModal();
  return (
    <Container>
      {phoneNumberA1 ? (
        <Wrapper>
          <PhoneNumber
            operator="a1"
            phoneNumber={phoneNumberA1}
            variant="small-bold"
          />
        </Wrapper>
      ) : null}
      {phoneNumberMts ? (
        <Wrapper>
          <PhoneNumber
            operator="mts"
            phoneNumber={phoneNumberMts}
            variant="small-bold"
          />
        </Wrapper>
      ) : null}
      <ButtonWrapper>
        <Button
          variant="call-me-solid"
          onClick={() => openModal(FooterCallForm, {})}
        >
          Перезвоните мне
        </Button>
      </ButtonWrapper>
      {workTime ? (
        <Wrapper>
          <TextWithIcon icon="clock" text={workTime} />
        </Wrapper>
      ) : null}
      {email ? (
        <Wrapper>
          <TextWithIcon icon="email" text={email} to={`mailto:${email}`} />
        </Wrapper>
      ) : null}
    </Container>
  );
}

const Container = styled.div``;

const Wrapper = styled.div`
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

export default Contact;
