import React, { useMemo } from 'react';
import styled from 'styled-components';

import { MenuItemType } from '@tager/web-modules';
import { useModal } from '@tager/web-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import useSettingItem from '@/hooks/useSettingItem';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { useTypedSelector } from '@/store/store';
import ContentContainer from '@/components/ContentContainer';
import { FooterCallForm } from '@/components/form/CallForm';
import MessengerIcon from '@/components/MessengerIcon';
import { ReactComponent as PhoneIcon } from '@/assets/svg/phone-1.svg';

function TopSamovarkin() {
  const openModal = useModal();
  const phoneNumber = useSettingItem('CONTACTS_PHONE');
  const workTime = useSettingItem('FOOTER_WORK_TIME');
  const email = useSettingItem('CONTACTS_EMAIL');
  const vk = useSettingItem('SOCIAL_VKONTAKTE');
  const facebook = useSettingItem('SOCIAL_FACEBOOK');
  const instagram = useSettingItem('SOCIAL_INSTAGRAM');

  const footerMenuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  const sourceArray = footerMenuItemList[0]?.children;

  const footerMenuItemListMapped = useMemo(() => {
    if (!sourceArray) {
      return [];
    }

    const middleIndex =
      sourceArray.length % 2
        ? (sourceArray.length - 1) / 2
        : sourceArray.length / 2 - 1;

    const mappedSourceArray = sourceArray.map((child, index, array) =>
      index % 2 ? array[middleIndex + (index + 1) / 2] : array[index / 2]
    );

    return [
      {
        ...footerMenuItemList[0],
        children: mappedSourceArray,
      },
      ...footerMenuItemList.slice(1),
    ];
  }, [footerMenuItemList, sourceArray]);

  return (
    <ContentContainer>
      <Container>
        {footerMenuItemListMapped.map((navigationItem: MenuItemType) => (
          <Column key={navigationItem.id}>
            {navigationItem.link ? (
              <ColumnLink
                to={navigationItem.link}
                target={navigationItem.isNewTab ? '_blank' : '_self'}
              >
                {navigationItem.label}
              </ColumnLink>
            ) : (
              <ColumnTitle>{navigationItem.label}</ColumnTitle>
            )}
            <WrapperItems>
              {navigationItem.children.map((menuItem) => (
                <ColumnItem key={menuItem.id}>
                  <Link
                    to={menuItem.link}
                    target={menuItem.isNewTab ? '_blank' : '_self'}
                  >
                    {menuItem.label}
                  </Link>
                </ColumnItem>
              ))}
            </WrapperItems>
          </Column>
        ))}

        <Column>
          <ColumnTitle>Контакты</ColumnTitle>
          <Phone>{phoneNumber}</Phone>
          <WorkTime>{workTime}</WorkTime>
          <CallButton onClick={() => openModal(FooterCallForm, {})}>
            <PhoneIcon />
            Обратный звонок
          </CallButton>
          <Email to={`mailto:${email}`}>{email}</Email>
          <Icons>
            {vk ? (
              <MessengerItem>
                <MessengerIcon messenger="vkSamovarkin" phoneOrChannel={vk} />
              </MessengerItem>
            ) : null}
            {facebook ? (
              <MessengerItem>
                <MessengerIcon
                  messenger="facebookSamovarkin"
                  phoneOrChannel={facebook}
                />
              </MessengerItem>
            ) : null}
            {instagram ? (
              <MessengerItem>
                <MessengerIcon
                  messenger="instagramSamovarkin"
                  phoneOrChannel={instagram}
                />
              </MessengerItem>
            ) : null}
          </Icons>
        </Column>
      </Container>
    </ContentContainer>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 0 40px;
`;

const Column = styled.div`
  flex: 0.5;
  &:first-child {
    flex: 1;
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }
  }
`;

const ColumnTitle = styled.span`
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
`;

const WrapperItems = styled.ul`
  margin-top: 20px;
`;

const ColumnLink = styled(Link)`
  display: block;
  font-weight: 800;
  &:hover {
    opacity: 0.7;
  }
`;

const ColumnItem = styled.li`
  display: block;
  font-size: 14px;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 400;
  transition: 0.3s;
  flex-basis: 50%;
  &:hover {
    opacity: 0.7;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const Phone = styled.span`
  display: block;
  line-height: 19px;
  font-weight: 600;
  margin-top: 15px;
`;

const WorkTime = styled.span`
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin-top: 5px;
`;

const CallButton = styled.button`
  display: flex;
  align-items: center;
  height: 38px;
  font-size: 13.5px;
  margin-top: 15px;
  padding: 0 15px;
  border-radius: 3px;
  border: 1.5px solid rgb(248, 214, 228, 0.7);
  color: ${colors.white};
  transition: 0.3s;
  &:hover {
    border-color: ${colors.white};
  }
  svg {
    fill: ${colors.white};
    margin-right: 10px;
  }
`;

const Email = styled(Link)`
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-top: 25px;
  &:hover {
    opacity: 0.7;
  }
`;

const Icons = styled.ul`
  display: flex;
  margin-top: 25px;
`;

const MessengerItem = styled.li`
  svg {
    height: 42px;
    width: 42px;
    margin-right: 10px;
  }
`;

export default TopSamovarkin;
