export const colors = {
  black: 'black',
  white: 'white',
  black100: '#101010',
  black200: '#262626',
  black300: '#3b3b3b',
  gray: '#f5f5f5',
  gray100: '#e9e9e9',
  gray200: '#d2d2d2',
  gray300: '#a8a8a8',
  gray400: '#5a5a5a',
  gray500: '#666666',
  red: '#f41924',
  primary: 'var(--main-color, #860935)',
  primary100: 'var(--main-color-hover, #6a072a)',
  green: '#36ba5f',
  violet: '#7f40bd',
  violet100: '#42223d',
  violet200: '#3A1d37',
} as const;

/** Source: https://htmlacademy.ru/blog/useful/css/short-14 */
const fallbackFont = ['Arial', 'sans-serif'].join(',');

export const fonts = {
  Montserrat: `'Montserrat', ${fallbackFont}`,
  AlternatesMontserrat: `'Montserrat Alternates', ${fallbackFont}`,
};

/**
 * Reference - Screen Resolution Stats Worldwide:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * 16px - scrollbar width on Windows,
 * some browsers doesn't include scrollbar width when calculate media queries
 */
export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 375,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 414,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,
  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  desktop: 1500,
};
