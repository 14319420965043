import { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { AvailabilityTypes } from '@/typings/model';
import { colors } from '@/constants/theme';

const AVAILABILITY_VARIANT = {
  AVAILABLE: 'AVAILABLE',
  ON_REQUEST: 'ON_REQUEST',
  OUT_OF_ORDER: 'OUT_OF_ORDER',
  'PRE-ORDER': 'PRE-ORDER',
} as const;

export function convertLabel(availability: string) {
  switch (availability) {
    case AVAILABILITY_VARIANT.AVAILABLE:
      return 'В наличии';
    case AVAILABILITY_VARIANT.ON_REQUEST:
      return 'Под заказ';
    case AVAILABILITY_VARIANT.OUT_OF_ORDER:
      return 'Нет в наличии';
    case AVAILABILITY_VARIANT['PRE-ORDER']:
      return 'Скоро в продаже';
    default:
      return null;
  }
}

export const variantCssMap: Record<AvailabilityTypes, CssSnippet> = {
  AVAILABLE: css`
    color: ${colors.green};
  `,
  ON_REQUEST: css`
    color: ${colors.red};
  `,
  OUT_OF_ORDER: css`
    color: ${colors.gray300};
  `,
  'PRE-ORDER': css`
    color: ${colors.black};
  `,
};
