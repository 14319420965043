import React from 'react';
import styled from 'styled-components';
import { colors } from '@/constants/theme';

import Button from '../Button';

type Props = React.ComponentProps<typeof Button> & {
  discount: number;
  children: React.ReactNode;
};

function DiscountButton({ discount, children, ...rest }: Props) {
  return (
    <StyledButton {...rest} variant="contained-primary-w100">
      {children}
      {/*<Discount>-{discount}%</Discount>*/}
    </StyledButton>
  );
}


const StyledButton = styled(Button)`
  font-size: 16px;
`

/*
const Discount = styled.i`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.white}
  font-style: normal;
`;*/

export default DiscountButton;
