import React from 'react';

import { Nullable } from '@tager/web-core';
import { SvgComponent } from '@tager/web-components';

import { ReactComponent as CartIcon } from '@/assets/svg/cart_calc.svg';
import { ReactComponent as ProfileIcon } from '@/assets/svg/profile.svg';
import { ReactComponent as PhoneIcon } from '@/assets/svg/phone-1.svg';

import * as S from './TextInput.style';

type InputIconType = 'cart' | 'profile' | 'phone';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  invalid?: boolean;
  icon?: InputIconType;
};

export type InputRef = HTMLInputElement;

function getIconComponent(
  icon: InputIconType | undefined
): Nullable<SvgComponent> {
  switch (icon) {
    case 'cart':
      return CartIcon;
    case 'profile':
      return ProfileIcon;
    case 'phone':
      return PhoneIcon;
    default:
      return null;
  }
}

function TextInput(
  { className, disabled, invalid, icon, ...rest }: Props,
  ref: React.Ref<InputRef>
) {
  const Icon = getIconComponent(icon);
  return (
    <S.Container className={className}>
      {Icon ? <S.IconComponent as={Icon} /> : null}
      <S.Input
        ref={ref}
        {...rest}
        withIcon={Boolean(icon)}
        disabled={disabled}
        invalid={invalid}
      />
    </S.Container>
  );
}

export default React.forwardRef<InputRef, Props>(TextInput);
