import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';

type Props = {
  onClose: () => void;
};

function ModalCloseButton({ onClose }: Props) {
  return (
    <CloseButton onClick={onClose}>
      <CloseIcon />
    </CloseButton>
  );
}

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 17px;
  width: 16px;
  height: 16px;
  &:hover {
    opacity: 0.75;
  }
`;

export default ModalCloseButton;
