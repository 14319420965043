import { CssSnippet } from '@tager/web-components';

import { PhoneNumberVariant } from './PhoneNumber.types';
import { BigBoldMixin, BigMixin, SmallBoldMixin } from './PhoneNumber.style';

export function getContentStyle(
  variant: PhoneNumberVariant
): CssSnippet | null {
  switch (variant) {
    case 'big':
      return BigMixin;
    case 'small-bold':
      return SmallBoldMixin;
    case 'big-bold':
      return BigBoldMixin;
    default:
      return null;
  }
}
