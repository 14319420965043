import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { FETCH_STATUSES, useOnClickOutside } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { selectCatalogMenuResource } from '@/store/reducers/tager/menus';
import { ReactComponent as ArrowIcon } from '@/assets/svg/dropdown_arrow.svg';
import ContentContainer from '@/components/ContentContainer';
import Link from '@/components/Link';
import Spinner from '@/components/Spinner';

import FirstSubcategories from './components/FirstSubcategories';
import SecondSubcategories from './components/SecondSubcategories';

type Props = {
  isVisible: boolean;
  setVisible: (isOpened: boolean) => void;
  setMounted: (isMounted: boolean) => void;
  className?: string;
};

function Catalog({ isVisible, setVisible, setMounted }: Props) {
  const catalogResource = useSelector(selectCatalogMenuResource);
  const catalogList = catalogResource?.data;
  const [isActive, setActive] = useState(catalogList[0]?.label);
  let distanceToTop: number = 0;

  useEffect(() => {
    distanceToTop =
      window.pageYOffset +
      (document.getElementById('catalog-menu')?.getBoundingClientRect()
        .bottom ?? 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', function (e) {
      let lastScrollPosition = window.pageYOffset;

      if (lastScrollPosition > distanceToTop) {
        setVisible(false);
      }
    });
  }, [distanceToTop]);

  useEffect(() => {
    requestAnimationFrame(() => requestAnimationFrame(() => setVisible(true)));
  }, []);

  const activeCatalogCategory = catalogList.find(
    (furnitureCategory) => furnitureCategory.label === isActive
  );

  const ref = useRef(null);
  useOnClickOutside(ref, ({ target }) => {
    if (
      !document.getElementById('catalog-opener')?.contains(target as Node) &&
      isVisible
    ) {
      setVisible(false);
    }
  });

  return (
    <Container
      isVisible={isVisible}
      onTransitionEnd={() => !isVisible && setMounted(false)}
    >
      <ContentContainer>
        <Inner ref={ref} id="catalog-menu">
          {catalogResource.status === FETCH_STATUSES.LOADING ? (
            <Spinner />
          ) : catalogResource.status === FETCH_STATUSES.SUCCESS &&
            !!catalogList.length ? (
            <>
              <CatalogCategories>
                {catalogList.map((furnitureCategory, index) => {
                  let timeoutId: number | undefined = undefined;
                  return (
                    <StyledCategoriesLink
                      key={furnitureCategory.label + index}
                      to={furnitureCategory.url}
                      onMouseOver={() => {
                        timeoutId = setTimeout(() => {
                          setActive(furnitureCategory.label);
                          timeoutId = undefined;
                        }, 200);
                      }}
                      onMouseLeave={() => clearTimeout(timeoutId)}
                      isActive={isActive === furnitureCategory.label}
                    >
                      <CategoriesTitle>
                        {furnitureCategory.label}
                      </CategoriesTitle>
                      {!(furnitureCategory.entityType === 'LINK') ? (
                        <IconWrapper
                          isActive={isActive === furnitureCategory.label}
                        >
                          <ArrowIcon />
                        </IconWrapper>
                      ) : null}
                    </StyledCategoriesLink>
                  );
                })}
              </CatalogCategories>
              {activeCatalogCategory &&
              !(activeCatalogCategory.entityType === 'LINK') ? (
                <Subcategories>
                  {!(activeCatalogCategory.view === 'PRODUCTS') ? (
                    <FirstSubcategories
                      activeCatalogCategory={activeCatalogCategory}
                    />
                  ) : null}
                  <SecondSubcategories
                    activeCatalogCategory={activeCatalogCategory}
                  />
                </Subcategories>
              ) : null}
            </>
          ) : null}
        </Inner>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div<{ isVisible: boolean }>`
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  min-width: 1260px;
  transition: all 0.3s;
  will-change: transform;

  ${({ isVisible }) =>
    isVisible
      ? css`
          transform: translateY(11px);
          opacity: 1;
        `
      : css`
          transform: translateY(26px);
          opacity: 0;
        `};
`;

const Inner = styled.div`
  display: flex;
  background: ${colors.white};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  position: relative;
  height: 580px;
  overflow: hidden;
`;

const CatalogCategories = styled.div`
  border-right: 1px solid ${colors.gray100};
  min-width: 200px;
  flex: 0 0 auto;
`;

const StyledCategoriesLink = styled(Link)<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ isActive }) => (isActive ? colors.primary : colors.black)};
  padding: 0 10px 0 20px;
  background: ${({ isActive }) => (isActive ? '#e7e7e7' : 'transparent')};
  cursor: pointer;
  &:hover {
    background: #e7e7e7;
  }
`;

const CategoriesTitle = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 15px;
  line-height: 58px;
  font-weight: 600;
  text-decoration: none;
`;

const IconWrapper = styled.div<{ isActive: boolean }>`
  height: 22px;
  margin-left: 20px;
  svg {
    transform: rotate(270deg);
    fill: ${({ isActive }) => (isActive ? colors.primary : colors.black)};
  }
`;

const Subcategories = styled.div`
  flex: 1;
`;

export default Catalog;
