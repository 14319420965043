import React from 'react';

import { SvgComponent, TagerLinkProps } from '@tager/web-components';
import { Nullable } from '@tager/web-core';

import { ReactComponent as MessageIcon } from '@/assets/svg/message.svg';
import { ReactComponent as ClockIcon } from '@/assets/svg/clock.svg';
import { ReactComponent as LocationIcon } from '@/assets/svg/location.svg';
import { ReactComponent as CalculatorIcon } from '@/assets/svg/calculator.svg';
import { StringFieldType } from '@/typings/common';

import { Text, TextLink } from './TextWithIcon.style';
import { IconType, ColorType } from './TextWithIcon.types';

export function getIconComponent(iconType: IconType): Nullable<SvgComponent> {
  switch (iconType) {
    case 'email':
      return MessageIcon;
    case 'clock':
      return ClockIcon;
    case 'location':
      return LocationIcon;
    case 'calculator':
      return CalculatorIcon;
    default:
      return null;
  }
}

type SimpleTextWithIconProps = {
  text?: StringFieldType;
  icon: IconType;
  bold?: boolean;
  className?: string;
};

function SimpleTextWithIcon({
  bold,
  text,
  icon,
  className,
}: SimpleTextWithIconProps) {
  const IconComponent = getIconComponent(icon);
  return (
    <Text bold={bold} className={className}>
      {IconComponent ? <IconComponent /> : null}
      {text ? text : null}
    </Text>
  );
}

type Props = SimpleTextWithIconProps & {
  to?: TagerLinkProps['to'];
  color?: ColorType;
};

function TextWithIcon({
  to,
  color = 'default',
  bold = false,
  text,
  icon,
  className,
}: Props) {
  const textWithIconProps: SimpleTextWithIconProps = {
    bold,
    text,
    icon,
    className,
  };

  if (to) {
    return (
      <TextLink color={color} to={to}>
        <SimpleTextWithIcon {...textWithIconProps} />
      </TextLink>
    );
  }
  return <SimpleTextWithIcon {...textWithIconProps} />;
}

export default TextWithIcon;
