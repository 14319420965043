import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { useTypedSelector } from '@/store/store';
import {
  getCatalogMenuThunk,
  selectMenuItemListByAlias,
} from '@/store/reducers/tager/menus';
import { ReactComponent as CatalogIcon } from '@/assets/svg/catalog.svg';
import { ReactComponent as BelsexCatalogIcon } from '@/assets/svg/catalog-1.svg';
import useSettingItem from '@/hooks/useSettingItem';
import { changeShadeColor, getCurrentProject } from '@/utils/common';
import useNavigationMenu from '@/hooks/useNavigationMenu';

import Catalog from './Catalog/Catalog';

function Bottom() {
  const {
    isNavigationVisible,
    isNavigationMounted,
    setNavigationVisible,
    setNavigationMounted,
    closeNavigation,
    toggleNavigation,
  } = useNavigationMenu();

  const mainColor = useSettingItem('PRIMARY_COLOR');
  const hasBackground = useSettingItem('HEADER_MENU_HAS_BACKGROUND');
  const headerMainMenuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'main')) ?? [];
  const project = getCurrentProject();
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    closeNavigation();
  }, [router.asPath]);

  useEffect(() => {
    dispatch(getCatalogMenuThunk());
  }, [dispatch, isNavigationVisible]);

  return (
    <Container hasBackgroundPrimaryColor={hasBackground}>
      <ContentContainer>
        <Menu>
          <CatalogItem>
            <CatalogButton
              id="catalog-opener"
              onClick={toggleNavigation}
              mainColor={mainColor}
              project={project}
              isOpen={isNavigationVisible}
            >
              {project === 'BELSEX' ? <BelsexCatalogIcon /> : <CatalogIcon />}
              <CatalogText>Весь каталог</CatalogText>
            </CatalogButton>
            {isNavigationMounted ? (
              <Catalog
                setMounted={setNavigationMounted}
                setVisible={setNavigationVisible}
                isVisible={isNavigationVisible}
              />
            ) : null}
          </CatalogItem>
          <Nav>
            <MenuItems>
              {headerMainMenuItemList.map((furnitureCategory) => {
                return (
                  <MenuItem
                    key={furnitureCategory.id}
                    hasBackgroundPrimaryColor={hasBackground}
                  >
                    <Link
                      to={furnitureCategory.link}
                      target={furnitureCategory.isNewTab ? '_blank' : '_self'}
                    >
                      <MenuItemText>{furnitureCategory.label}</MenuItemText>
                    </Link>
                  </MenuItem>
                );
              })}
            </MenuItems>
          </Nav>
        </Menu>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div<{ hasBackgroundPrimaryColor: boolean }>`
  ${({ hasBackgroundPrimaryColor }) =>
    hasBackgroundPrimaryColor
      ? css`
          background: ${colors.primary};
          color: ${colors.white};
        `
      : css`
          background: ${colors.gray};
        `};
  overflow: hidden;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;

const CatalogItem = styled.div`
  white-space: nowrap;
  margin-right: 60px;
  ${media.laptop(
    css`
      margin-right: 30px;
    `
  )};
`;

const Nav = styled.nav`
  width: 100%;
`;

const MenuItems = styled.ul`
  display: flex;
  justify-content: space-between;
`;

const MenuItem = styled.li<{ hasBackgroundPrimaryColor: boolean }>`
  white-space: nowrap;
  padding: 0 5px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    ${({ hasBackgroundPrimaryColor }) =>
      hasBackgroundPrimaryColor
        ? css`
            opacity: 0.7;
          `
        : css`
            color: ${colors.primary};
          `};
  }
`;

const MenuItemText = styled.span`
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
  display: block;

  ${media.laptop(
    css`
      font-size: 14px;
    `
  )};
`;

const CatalogText = styled.span`
  margin-left: 10px;
  display: block;
  font-weight: 700;
  font-size: 15px;
`;

const defaultColorMixin = css<{
  mainColor: string | null;
  isOpen: boolean;
}>`
  background: ${({ isOpen, mainColor }) =>
    isOpen ? colors.white : changeShadeColor(mainColor, 40)};

  svg {
    fill: ${({ isOpen }) => (isOpen ? colors.primary100 : colors.white)};
  }

  ${CatalogText} {
    color: ${({ isOpen }) => (isOpen ? colors.primary100 : colors.white)};
  }

  &:hover ${CatalogText} {
    color: ${colors.primary};
  }

  &:hover {
    background: ${colors.white};

    svg {
      fill: ${colors.primary};
    }
  }
`;

const belsexColorMixin = css<{
  mainColor: string | null;
  isOpen: boolean;
}>`
  &:hover {
    svg {
      opacity: 0.85;
    }
  }

  &:hover ${CatalogText} {
    opacity: 0.85;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          background: ${colors.white};
          color: ${colors.primary};

          svg {
            fill: ${colors.primary};
          }
        `
      : css`
          background: transparent;
          color: ${colors.white};

          svg {
            fill: ${colors.white};
          }
        `};
`;

const belmebelColorMixin = css<{
  isOpen: boolean;
}>`
  &:hover ${CatalogText} {
    opacity: 0.85;
  }

  &:hover {
    svg {
      opacity: 0.85;
    }
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          background: ${colors.primary};

          svg {
            fill: ${colors.white};
          }

          ${CatalogText} {
            color: ${colors.white};
          }
        `
      : css`
          background: transparent;

          svg {
            fill: ${colors.primary};
          }

          ${CatalogText} {
            color: ${colors.primary};
          }
        `};
`;

const CatalogButton = styled.button<{
  mainColor: string | null;
  isOpen: boolean;
  project: string | undefined;
}>`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  padding: 10px 12px;
  border-radius: 3px;
  ${({ project }) =>
    project === 'BELSEX'
      ? belsexColorMixin
      : project === 'BELMEBEL'
      ? belmebelColorMixin
      : defaultColorMixin};
`;

export default Bottom;
