import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { useModal } from '@tager/web-components';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { queryAdded, selectQuery } from '@/store/reducers/pages/search';
import { ReactComponent as HistoryIcon } from '@/assets/svg/history.svg';
import { normalizeThumbnail } from '@/utils/common';
import useSettingItem from '@/hooks/useSettingItem';
import Link from '@/components/Link';
import { PlainPicture } from '@/components/Picture';
import ContentContainer from '@/components/ContentContainer';
import SearchInput from '@/components/Input/SearchInput';

import { ToppedOverlay, Logo, CartLink } from '../../components/Middle';
import SearchModal from '../../components/SearchModal';

function MiddleSamovarkin() {
  const router = useRouter();
  const openModal = useModal();
  const dispatch = useDispatch();
  const searchQuery = useSelector(selectQuery);
  const logo = useSettingItem('HEADER_LOGO');
  const logo2x = useSettingItem('HEADER_LOGO_2X');

  const OpenSearchModal = () =>
    openModal(
      SearchModal,
      {},
      { components: { Overlay: ToppedOverlay }, withAnimation: false }
    );

  const openModalOrPage = !!searchQuery.trim().length
    ? () => router.push(`/search?query=${searchQuery}`)
    : OpenSearchModal;

  const thumbnail = normalizeThumbnail(logo, logo2x);

  return (
    <ContentContainer>
      <Content>
        {logo || logo2x ? (
          <Logo>
            <LogoLink to="/">
              <PlainPicture {...convertThumbnailToPictureProps(thumbnail)} />
            </LogoLink>
          </Logo>
        ) : null}

        <StyledSearchInput
          variant="primary"
          borderColor="gray"
          placeholder="Поиск по каталогу"
          value={searchQuery}
          onChange={(e) => {
            dispatch(queryAdded(e.target.value));
            OpenSearchModal();
          }}
          onKeyDown={(event) =>
            event.keyCode === 13 && !!searchQuery.trim().length
              ? router.push(`/search?query=${searchQuery}`)
              : OpenSearchModal
          }
          onClickButton={openModalOrPage}
        />

        <CartLink to="/history">
          <HistoryIcon />
        </CartLink>
      </Content>
    </ContentContainer>
  );
}

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  min-height: 95px;
`;

const LogoLink = styled(Link)`
  img {
    max-width: 250px;
    max-height: 70px;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  width: 670px;
`;

export default MiddleSamovarkin;
