import styled from 'styled-components';

import { colors } from '@/constants/theme';

function getBorderColor(
  props: { invalid?: boolean; isActive?: boolean } = {}
): string {
  return props.invalid
    ? colors.red
    : props.isActive
    ? colors.gray500
    : colors.gray200;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const TextArea = styled.textarea<{
  isActive?: boolean;
  withLabel?: boolean;
  invalid?: boolean;
  required?: boolean;
}>`
  width: 100%;
  font-size: 14px;
  line-height: 130%;
  border-radius: 5px;
  border: 1px solid ${({ invalid }) => getBorderColor({ invalid })};
  padding: 15px;
  min-height: 120px;
  max-height: 200px;
  resize: none;

  &::placeholder {
    color: ${(props) => (props.invalid ? colors.red : colors.black)};
    opacity: 0.5;
  }

  &:focus {
    border-color: ${({ invalid, readOnly }) =>
      getBorderColor({ invalid, isActive: !readOnly })};
  }
`;

export const Label = styled.label<{
  isActive?: boolean;
  isFilled?: boolean;
  invalid?: boolean;
}>`
  position: absolute;
  top: ${({ isActive, isFilled }) =>
    isActive || isFilled ? '10px' : 'calc(50% - 3px)'};
  left: 15px;
  transform: ${({ isActive, isFilled }) =>
    isActive || isFilled ? 'translateY(-50%) scale(.7)' : 'translateY(-50%)'};
  transform-origin: 0 50%;
  color: ${(props) => (props.invalid ? colors.red : colors.black)};
  pointer-events: none;
`;
