import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  height: number;
  color?: string;
};

function PlaceholderCard({ height, color }: Props) {
  return (
    <Content height={height} color={color}>
      <LoaderWrapper>
        <LoaderComponent>
          <div />
          <div />
        </LoaderComponent>
      </LoaderWrapper>
    </Content>
  );
}

const Content = styled.a<{ height: number; color?: string }>`
  display: flex;
  position: relative;
  transition: 0.3s;
  border: 1px solid ${colors.gray100};
  cursor: pointer;
  min-height: 75px;
  height: ${(props) => props.height}px;
  width: 100%;
  background: ${(props) => (props.color ? props.color : colors.white)};
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const LoaderComponent = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    border: 4px solid ${colors.primary100};
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
`;

export default PlaceholderCard;
