import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

export const Container = styled.div`
  position: relative;
`;

export const Input = styled.input<{
  variant: 'default' | 'primary';
  borderColor?: 'primary' | 'gray' | 'none';
}>`
  width: 100%;
  border: ${(props) =>
    props.variant === 'default' || props.borderColor === 'none'
      ? `none`
      : `1px solid ${
          props.borderColor === 'gray' ? colors.gray200 : colors.primary
        }`};
  height: 40px;
  font-size: 16px;
  padding: 0 50px 0 15px;
  border-radius: 5px;
  background: ${({ variant }) =>
    variant === 'default' ? colors.gray100 : colors.white};

  &::placeholder {
    color: ${colors.gray500};
    opacity: 0.7;
  }

  &:focus {
    border-color: ${colors.primary100};
  }
`;

export const prefixCss = css`
  display: block;
  height: 18px;
  width: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  fill: ${colors.primary};
  &:hover {
    fill: ${colors.primary100};
  }
`;

export const IconComponent = styled.svg`
  ${prefixCss};
`;

export const SearchButton = styled.button`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;
