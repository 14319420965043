import React from 'react';
import styled from 'styled-components';

import { ReactComponent as WhatAppIcon } from '@/assets/svg/social/whatsapp.svg';
import { ReactComponent as TelegramIcon } from '@/assets/svg/social/telegram.svg';
import { ReactComponent as ViberIcon } from '@/assets/svg/social/viber2.svg';
import { ReactComponent as FacebookSamovarkinIcon } from '@/assets/svg/social/samovarkin/fb.svg';
import { ReactComponent as InstagramSamovarkinIcon } from '@/assets/svg/social/samovarkin/insta.svg';
import { ReactComponent as VkSamovarkinIcon } from '@/assets/svg/social/samovarkin/vk.svg';
import { ReactComponent as FacebookBelsexIcon } from '@/assets/svg/social/belsex/facebook.svg';
import { ReactComponent as InstagramBelsexIcon } from '@/assets/svg/social/belsex/instagram.svg';
import { ReactComponent as VkBelsexIcon } from '@/assets/svg/social/belsex/vk.svg';
import { ReactComponent as YoutubeBelsexIcon } from '@/assets/svg/social/belsex/youtube.svg';
import { normalizePhoneNumber } from '@/utils/common';
import { MessengerType } from '@/typings/common';

type Props = {
  messenger: MessengerType;
  phoneOrChannel?: string;
  className?: string;
};

function MessengerIcon({ messenger, phoneOrChannel, className }: Props) {
  return (
    <IconLink href={getHref(messenger, phoneOrChannel)} className={className}>
      {getMessengerIcon(messenger)}
    </IconLink>
  );
}

function getMessengerIcon(messenger: MessengerType) {
  switch (messenger) {
    case 'whatsapp':
      return <WhatAppIcon />;
    case 'viber':
      return <ViberIcon />;
    case 'telegram':
      return <TelegramIcon />;
    case 'vkSamovarkin':
      return <VkSamovarkinIcon />;
    case 'facebookSamovarkin':
      return <FacebookSamovarkinIcon />;
    case 'instagramSamovarkin':
      return <InstagramSamovarkinIcon />;
    case 'facebookBelsex':
      return <FacebookBelsexIcon />;
    case 'instagramBelsex':
      return <InstagramBelsexIcon />;
    case 'vkBelsex':
      return <VkBelsexIcon />;
    case 'youtubeBelsex':
      return <YoutubeBelsexIcon />;
    default:
      return null;
  }
}

function getHref(messenger: MessengerType, phoneOrChannel?: string): string {
  const phone = phoneOrChannel ?? '';
  switch (messenger) {
    case 'whatsapp':
      return `whatsapp://send?phone=${normalizePhoneNumber(phone)}`;
    case 'viber':
      return `viber://chat?number=${normalizePhoneNumber(phone)}`;
    case 'telegram':
      return `tg://resolve?domain=${phoneOrChannel}`;
    case 'instagramSamovarkin':
    case 'instagramBelsex':
      return `https://www.instagram.com/${phoneOrChannel}`;
    case 'facebookSamovarkin':
    case 'facebookBelsex':
      return `https://www.facebook.com/${phoneOrChannel}`;
    case 'vkSamovarkin':
    case 'vkBelsex':
      return `https://vk.com/${phoneOrChannel}`;
    case 'youtubeBelsex':
      return `https://www.youtube.com/user/${phoneOrChannel}`;
    default:
      return '';
  }
}

const IconLink = styled.a`
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export default MessengerIcon;
