import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { ModalProps } from '@tager/web-components';
import { useDebounce } from '@tager/web-core';

import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';
import { colors } from '@/constants/theme';
import {
  getSearchProductListThunk,
  getSearchPostListThunk,
  getSearchCategoryListThunk,
  selectQuery,
  queryAdded,
} from '@/store/reducers/pages/search';
import SearchTitle from '@/components/Title/SearchTitle';
import SearchInput from '@/components/Input/SearchInput';
import TabsBlock from '@/components/TabsBlock';

import SearchModalResultCatalog from './components/SearchModalResultsCatalog';
import SearchModalResultsBlog from './components/SearchModalResultsBlog';

type Props = ModalProps;

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const tabs = [
  { name: 'В каталоге', value: 'CATALOG' },
  { name: 'В статьях', value: 'BLOG' },
];

function SearchModal({ closeModal }: Props) {
  const [isActiveTab, setActiveTabs] = useState('CATALOG');
  const searchQuery = useSelector(selectQuery);
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const dispatch = useDispatch();
  const router = useRouter();
  const prevPath = usePrevious(router.asPath);

  useEffect(() => {
    dispatch(getSearchProductListThunk(debouncedSearchQuery, 'searchInput'));
    dispatch(getSearchPostListThunk(debouncedSearchQuery, 'searchInput'));
    dispatch(getSearchCategoryListThunk(debouncedSearchQuery, 'searchInput'));
  }, [debouncedSearchQuery, dispatch]);

  useEffect(() => {
    if (prevPath && prevPath !== router.asPath) {
      closeModal();
    }
  }, [router.asPath, prevPath, closeModal]);

  return (
    <Modal>
      <Container>
        <Search>
          <StyledSearchInput
            variant="primary"
            placeholder="Поиск"
            autoFocus
            onChange={(e) => dispatch(queryAdded(e.target.value))}
            onClickButton={() =>
              !!searchQuery.trim().length &&
              router.push(`/search?query=${searchQuery}`)
            }
            onKeyDown={(event) =>
              event.keyCode === 13 &&
              !!searchQuery.trim().length &&
              router.push(`/search?query=${searchQuery}`)
            }
            value={searchQuery}
          />

          <CloseButton onClick={closeModal}>
            <CloseIcon />
          </CloseButton>
        </Search>

        <StyledTabsBlock
          variant="small"
          tabs={tabs}
          activeTab={isActiveTab}
          setActiveTab={setActiveTabs}
        />

        <Results>
          {!searchQuery ? (
            <SearchTitle title="Введите в поисковую строку ваш запрос" />
          ) : isActiveTab === 'CATALOG' ? (
            <SearchModalResultCatalog />
          ) : (
            <SearchModalResultsBlog />
          )}
        </Results>
      </Container>
    </Modal>
  );
}
const Modal = styled.div`
  position: relative;
  background: ${colors.white};
  z-index: 11;
  overflow: auto;
  width: min(1500px, 90vw);
  min-height: 350px;
  max-height: 90vh;
`;

const Container = styled.div`
  min-width: 1100px;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 50px 20px;
`;

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
`;

const CloseButton = styled.button`
  margin-left: 15px;
  svg {
    display: block;
    fill: ${colors.primary};
  }

  &:hover {
    svg {
      fill: ${colors.primary100};
    }
  }
`;

const StyledTabsBlock = styled(TabsBlock)`
  padding: 0 20px;
`;

const Results = styled.div`
  padding: 20px 50px;
`;

export default SearchModal;
