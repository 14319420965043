import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

import Bottom from '../components/Bottom';

import TopBelsex from './components/TopBelsex';
import MiddleBelsex from './components/MiddleBelsex';

function HeaderBelsex() {
  return (
    <HeaderContainer>
      <TopBelsex />
      <MiddleBelsex />
      <Bottom />
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  color: ${colors.white};
`;

export default HeaderBelsex;
