import React from 'react';
import styled from 'styled-components';
import { Formik, Form, FormikHelpers } from 'formik';

import { sendFeedbackForm, FeedbackFormPayload } from '@/services/requests';
import Button from '@/components/Button';
import { TextInputFormik } from '@/components/Input/TextInput';
import { TextAreaFormik } from '@/components/TextArea';
import {
  areAllErrorsVisible,
  validatePhone,
} from '@/components/form/form.helpers';

type Props = {
  setFeedbackFormClosed: () => void;
};

function validate({ phone, message, name }: FeedbackFormPayload) {
  const errors: Partial<FeedbackFormPayload> = {};
  if (!validatePhone(phone)) {
    errors.phone = 'Неверный номер телефона';
  }
  if (!phone) {
    errors.phone = 'Поле обязательно для заполнения';
  }
  if (!name) {
    errors.name = 'Поле обязательно для заполнения';
  }
  if (!message) {
    errors.message = 'Поле обязательно для заполнения';
  }
  return errors;
}

const hasFrontendValidationErrors = (values: FeedbackFormPayload) =>
  !!Object.keys(validate(values)).length;

function FeedbackForm({ setFeedbackFormClosed }: Props) {
  function handleSubmit(
    values: FeedbackFormPayload,
    formikHelpers: FormikHelpers<FeedbackFormPayload>
  ) {
    return sendFeedbackForm(values)
      .then(() => {
        setFeedbackFormClosed();
        formikHelpers.setSubmitting(true);
      })
      .catch(() => {
        formikHelpers.setErrors({
          message: 'Произошла ошибка при отправке данных',
        });
        formikHelpers.setStatus({
          hasBackendError: true,
        });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }
  return (
    <Container>
      <Text>Написать сообщение</Text>
      <Formik<FeedbackFormPayload>
        initialValues={{ name: '', phone: '', message: '' }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ isSubmitting, isValid, values, dirty, ...props }) => (
          <Form>
            <FormRow>
              <TextInputFormik
                name="name"
                icon="profile"
                placeholder="Как вас зовут?"
                invalid={false}
                autoComplete="off"
              />
            </FormRow>
            <FormRow>
              <TextInputFormik
                name="phone"
                icon="phone"
                placeholder="Ваш номер телефона"
                invalid={false}
                autoComplete="off"
              />
            </FormRow>
            <FormRow>
              <StyledTextArea
                placeholder="Введите ваше сообщение"
                name="message"
                invalid={false}
              />
            </FormRow>
            <Button
              type="submit"
              variant="contained-primary-w100"
              disabled={
                !(isValid || !hasFrontendValidationErrors(values)) &&
                areAllErrorsVisible(props.errors, props.touched)
              }
              children="Отправить"
              loading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  padding: 40px 50px;
`;

const Text = styled.div`
  font-size: 30px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 40px;
`;

const FormRow = styled.div`
  margin-bottom: 30px;
`;

const StyledTextArea = styled(TextAreaFormik)`
  textarea {
    height: 160px;
  }
`;

export default FeedbackForm;
