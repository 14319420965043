import { useContext } from 'react';

import {
  NavigationMenuContextType,
  NavigationMenuContext,
} from '@/providers/NavigationMenuProvider';

function useNavigationMenu() {
  const context = useContext(NavigationMenuContext);

  if (!context) {
    throw new Error('use only provider hook');
  }

  return context as NavigationMenuContextType;
}

export default useNavigationMenu;
