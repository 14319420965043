import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';

import Top from './components/Top';
import Bottom from './components/Bottom';
import Messengers from './components/Messengers';

function FooterBelkids() {
  return (
    <FooterContainer>
      <ContentContainer>
        <Top />
        <Bottom />
        <Messengers />
      </ContentContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background: ${colors.gray};
`;

export default FooterBelkids;
